import { MEDIA_KEY_ENUM, headerFormCardProps } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { getAccountAnalysis } from '@/services/account';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import ActiveAudienceTimeBarChart from '../charts/ActiveAudienceTimeBarChart';
import AgePieChart from '../charts/AgePieChart';
import GenderPieChart from '../charts/GenderPieChart';
import TopBarChart from '../charts/TopBarChart';
import { dict } from '@/hooks/useChangeLocale';

//抖音粉丝
export const DouyinFunsContent = ({ accountId }) => {
  const { user } = useAppContext();

  //加载用户分析相关数据
  const { data: userAnalysisData, loading } = useRequest(
    () => getAccountAnalysis(accountId, user?.bid, MEDIA_KEY_ENUM.DOUYIN),
    {
      refreshDeps: [user?.bid, accountId],
    },
  );

  return (
    <ProCard.Group gutter={[16, 16]} wrap loading={loading}>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER_DISTRIBUTION')}>
        <GenderPieChart data={userAnalysisData} />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={12} title={dict('AGE_DISTRIBUTION')}>
        <AgePieChart data={userAnalysisData} />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={24} title={dict('ACCOUNT_REPORT_AUDIENCE_ACTIVE_TIME')}>
        <ActiveAudienceTimeBarChart data={userAnalysisData} />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={12} title={dict('ACCOUNT_REPORT_PROVINCE_DISTRIBUTION')}>
        <TopBarChart diminDatas={userAnalysisData} type="province" />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={12} title={dict('ACCOUNT_REPORT_PROVINCE_DISTRIBUTION')}>
        <TopBarChart diminDatas={userAnalysisData} type="crowd" />
      </ProCard>
    </ProCard.Group>
  );
};
