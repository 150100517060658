import ChartCore from '@/components/ChartCore';
import { dict } from '@/hooks/useChangeLocale';
import { LiveStreamDetail } from '@/services/live_stream/all/type';
import { Format } from '@/utils/format';
import { useCreation } from 'ahooks';

const Chart = ({ data, liveStreamData }: { data: any; liveStreamData?: LiveStreamDetail }) => {
  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {
        formatter: '{b}: {c}%',
      },
      title: {
        text: dict('LIVE_VIEW_USER_COUNT'),
        subtext: Format.toAmountNumber(liveStreamData?.liveDataVO?.lpScreenLiveWatchUv),
        left: 'center',
        top: 'center',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          label: {
            show: true,
            formatter: '{b}\n {c}%',
          },

          data: Object.entries(data || {}).map(([name, value]) => ({ name: getAgeLabel(name), value })),
        },
      ],
    }),
    [data, liveStreamData],
  );

  return <ChartCore option={options} notMerge empty={!data} style={{ height: 300 }} />;
};

const getAgeLabel = (name) => {
  if (name.endsWith('-')) return dict('LIVE_STATIST_AGE_ABOUT', { age: name.substr(0, name.length - 1) });
  return dict('LIVE_STATIST_AGE', { age: name });
};

export default Chart;
