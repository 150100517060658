import { dict } from '@/hooks/useChangeLocale';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { DyShortVideoVO } from '@/services/live_stream/dy/type';
import { DateUtils } from '@/utils/dateUtil';
import { Table } from '@antd';

type ListPros = {
  list?: DyShortVideoVO[];
};
const List = ({ list }: ListPros) => {
  const dataList: DyShortVideoVO[] = list || [];
  const { tableProps } = useTableRequest(
    async ({ sorter }) => {
      const { field, order } = sorter || {};
      const orderList = field && order ? [...dataList].sort((v1, v2) => v1[field] - v2[field]) : dataList;
      if (order == 'descend') {
        orderList.reverse();
      }
      return {
        list: orderList.map((v, index) => ({
          orderIndex: index + 1,
          ...v,
        })),
      };
    },
    { refreshDeps: [list] },
  );

  return (
    <Table
      {...tableProps}
      size="small"
      columns={[
        getTableColumn({ title: dict('RANKINGS'), dataIndex: 'orderIndex' }),
        getTableColumn({
          title: dict('VIDEO_INFO'),
          dataIndex: 'itemTitle',
          width: 150,
          ellipsis: true,
        }),
        getTableColumn({
          title: dict('PUBLISH_TIME'),
          dataIndex: 'itemPublishTime',
          render: (v) => DateUtils.formatDateTime(v),
        }),
        getTableColumn({
          title: dict('LP_SCREEN_GUIDE_ITEM_COUNT'),
          dataIndex: 'lpScreenGuideItemShowCount',
          sorter: true,
        }),
        getTableColumn({
          title: dict('LP_SCREEN_GUIDE_ITEM_COUNT'),
          dataIndex: 'lpScreenGuideItemWatchCount',
          sorter: true,
        }),
        getTableColumn({
          title: dict('LP_SCREEN_GUIDE_ITEM_ENTER_RATIO'),
          dataIndex: 'lpScreenGuideItemEnterRatio',
          sorter: true,
        }),
      ]}
    />
  );
};

export default List;
