/* eslint-disable @typescript-eslint/no-empty-function */
import { defaultFormItemConfig } from '@/constants/const';
import { REGEX } from '@/constants/regex';
import { useAppContext } from '@/contexts/AppContext';
import { dict, supportLaunguageKeys, useChangeLanguageLocale } from '@/hooks/useChangeLocale';
import { HeadNav } from '@/icons';
import { sendEmit } from '@/layouts/const/config.var';
import { updateUserPassword } from '@/services/user';
import { GlobalOutlined, KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import { App, Dropdown, Form, Input, Modal, ModalProps } from '@antd';
import { useBoolean, useMemoizedFn } from 'ahooks';
import { Function0 } from 'lodash';
import { useEffect } from 'react';
import styles from './style.less';

enum MenuType {
  USER_CENTER,
  CHANGE_PASSWD,
  EXIT,
}
const renderMenuItem = (name: string, icon?: React.ReactNode) => {
  return (
    <div className={styles['menu-item']}>
      <div>{name}</div>
      {icon}
    </div>
  );
};

export default () => {
  const { user } = useAppContext();

  const [ChangePasswordModalModal, showModal] = useChangePwdModal();
  const { languageKey, changeLanguageLocale } = useChangeLanguageLocale();

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        menu={{
          items: [
            {
              key: MenuType.USER_CENTER,
              disabled: true,
              className: styles['user-info'],
              label: (
                <div className={styles['top-content']}>
                  <div className={styles['icon-header-normal']} />
                  <div className={styles['userinfo-wrap']}>{user?.loginId}</div>
                </div>
              ),
            },

            {
              type: 'divider',
            },
            {
              type: 'group',
              label: renderMenuItem(dict('MULTILINGUAL'), <GlobalOutlined />),
              children: supportLaunguageKeys.map(({ key, label }) => ({
                key,
                disabled: key == languageKey,
                label: renderMenuItem(label),
                onClick: () => {
                  changeLanguageLocale(key);
                },
              })),
            },
            {
              key: MenuType.CHANGE_PASSWD,
              label: renderMenuItem(dict('CHANGE_PASSWORD'), <KeyOutlined />),
              onClick: showModal,
            },
            {
              type: 'divider',
            },
            {
              key: MenuType.EXIT,
              label: renderMenuItem(dict('EXIT'), <LogoutOutlined />),
              onClick: () => sendEmit('LOGOUT'),
            },
          ],
        }}
      >
        <HeadNav title={user?.loginId} style={{ fontSize: 35 }} />
      </Dropdown>
      <ChangePasswordModalModal />
    </div>
  );
};

type ChangePasswordModalProps = ModalProps;
const useChangePwdModal = (): [React.FC<ChangePasswordModalProps>, Function0<void>] => {
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const ChangePasswordModalModal = useMemoizedFn((params: ChangePasswordModalProps) => {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const { user } = useAppContext();
    const newPassword = Form.useWatch('newPassword', form);

    useEffect(() => form?.resetFields(), [isModalOpen, form]);

    return (
      <Modal
        title={dict('CHANGE_PASSWORD')}
        open={isModalOpen}
        onCancel={hideModal}
        destroyOnClose
        {...params}
        onOk={async () => {
          try {
            const params = await form.validateFields();
            const result = await updateUserPassword({ ...params, loginId: user?.loginId });
            if (result) {
              message.success(dict('CHANGE_PASSWORD_SUCCESS'));
              hideModal();
              sendEmit('LOGOUT');
            }
          } catch (error) {}
        }}
        width={600}
      >
        <Form
          {...defaultFormItemConfig}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          validateTrigger={['onSubmit', 'onBlur', 'onChange']}
        >
          <Form.Item name="oldPassword" required label={dict('OLD_PASSWORD')} rules={[{ required: true }]}>
            <Input.Password
              hidden
              autoComplete="new-password"
              placeholder={dict('PLEASE_TYPE_NAME', { name: dict('OLD_PASSWORD') })}
            />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label={dict('NEW_PASSWORD')}
            required
            rules={[
              {
                required: true,
                message: dict('PWD_RULE_TIP'),
                pattern: REGEX.PWD,
              },
            ]}
          >
            <Input.Password
              placeholder={dict('PLEASE_TYPE_NAME', { name: dict('NEW_PASSWORD') })}
              hidden
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={dict('CONFIRM_PASSWORD')}
            required
            rules={[
              {
                required: true,
                validateTrigger: 'onSubmit',
                validator(_, value) {
                  return value == newPassword ? Promise.resolve() : Promise.reject(dict('CONFRIM_PWD_NOT_SAME_TIP'));
                },
              },
            ]}
          >
            <Input.Password
              placeholder={dict('PLEASE_TYPE_NAME', { name: dict('NEW_PASSWORD') })}
              hidden
              autoComplete="new-password"
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  });

  return [ChangePasswordModalModal, showModal];
};
