import AccountSelect from '@/components/Form/Field/select/AccountSelect';
import EnumSelect from '@/components/Form/Field/select/EnumSelect';
import { OrgTreeSelect } from '@/components/Form/Field/select/OrgTreeSelect';
import Container, { Blank } from '@/components/PageCard';
import { TableQueryFilter } from '@/components/TableFilter';
import DateRangePicker, { rangePresetsToToday } from '@/components/TableFilter/RangePicker';
import { MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getHighDiveUserPage, highDiveUserDownload, highDiveUserUpdateTime } from '@/services/high-dive-user';
import { DateUtils } from '@/utils/dateUtil';
import { ProCard } from '@ant-design/pro-components';
import { Alert, Button, Form, Select, Space, Table, Typography } from '@antd';
import { useCreation, useRequest, useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { cloneDeep, snakeCase } from 'lodash';
import { useNavigate } from 'umi';
const { Link } = Typography;

const defaltParams = {
  orgId: [],
  actionTypes: [],
  accountIds: [],
  time: rangePresetsToToday[2].value,
  isFunds: '',
};

const List = () => {
  const navigate = useNavigate();
  const [baseParams, setBaseParams] = useSafeState<any>(cloneDeep(defaltParams));
  const { user } = useAppContext();

  const params = useCreation(() => {
    const { time, ...restParams } = baseParams;
    const [startTime, endTime] = DateUtils.convertRangeStr(time);
    return { ...restParams, actionTimeStart: startTime, actionTimeEnd: endTime };
  }, [baseParams]);
  const [queryParams, setQueryParams] = useSafeState<any>({});

  const { run: download, loading: downloading } = useRequest(() => highDiveUserDownload(queryParams), { manual: true });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const sortParams: any = {};

      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = snakeCase(sorter.field);
        sortParams.direction = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
      }

      const query = {
        ...params,
        ...sortParams,
        bid: user?.bid,
      };
      setQueryParams(query);
      return getHighDiveUserPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, user], debounceWait: 100 },
  );

  const { data: syncTime = '' } = useRequest(
    async () => {
      const time = await highDiveUserUpdateTime(user?.bid);
      if (!dayjs(time).isValid()) {
        return '';
      }
      return DateUtils.formatDateTime(time);
    },
    {
      refreshDeps: [user?.bid],
    },
  );

  const [filterForm] = Form.useForm();
  const orgIds = Form.useWatch('orgIds', filterForm);

  const { getBtnPermissionConfig } = usePermission();

  return (
    <Container>
      <Alert message={dict('DATA_UPDATE_TIME_TIP', { syncTime })} style={{ marginBottom: 10 }} />
      <ProCard>
        <TableQueryFilter
          initialValues={cloneDeep(defaltParams)}
          onFinish={async (values) => {
            setBaseParams(values);
          }}
          defaultCollapsed={false}
          form={filterForm}
        >
          <Form.Item name="orgIds" label={dict('AFFILIATION_ORG')}>
            <OrgTreeSelect
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('AFFILIATION_ORG') })}
              treeDefaultExpandAll
              multiple
              maxTagCount="responsive"
              triggerChildrenChecked
              onChange={() => {
                filterForm.setFieldValue('accountIds', undefined);
              }}
            />
          </Form.Item>
          <Form.Item name="accountIds" label={dict('SOURCE_TIKTOK_ACCOUNT')}>
            <AccountSelect
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('SOURCE_TIKTOK_ACCOUNT') })}
              mode="multiple"
              maxTagCount="responsive"
              filterMediaIds={MEDIA_KEY_ENUM.DOUYIN}
              filterOrgIds={orgIds}
            />
          </Form.Item>
          <Form.Item name="actionTypes" label={dict('INTERACTION_TYPE')}>
            <EnumSelect
              placeholder={dict('PLEASE_SELECT_MULTIPLE_NAME', { name: dict('INTERACTION_TYPE') })}
              type="ClueActionTypeEnum"
              mode="multiple"
              maxTagCount="responsive"
              allowClear
            />
          </Form.Item>

          <Form.Item name="time" label={dict('INTERACTIVE_TIME')}>
            <DateRangePicker style={{ width: '100%' }} endDay="today" allowClear={false} />
          </Form.Item>

          <Form.Item name="isFunds" label={dict('IS_FUNDS')}>
            <Select
              placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('IS_FUNDS') })}
              options={[
                {
                  label: dict('ALL'),
                  value: '',
                },
                {
                  label: dict('FUNDS_YES'),
                  value: 'true',
                },
                {
                  label: dict('FUNDS_NO'),
                  value: 'false',
                },
              ]}
            />
          </Form.Item>
        </TableQueryFilter>
      </ProCard>
      <Blank />

      <ProCard>
        <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'space-between' }}>
          <Button
            loading={downloading}
            type="primary"
            onClick={download}
            {...getBtnPermissionConfig(AUTH.CUSTOMER_HIGH_POTENTIAL_USER.DOWNLOAD)}
          >
            {dict('ACTION_DOWNLOAD_DATA')}
          </Button>
        </Space>
        <Table
          {...tableProps}
          rowKey="highId"
          columns={[
            getTableColumn({
              title: dict('USER_TIKTOK_NICKNAME'),
              dataIndex: 'nickName',
              render(value, { nickName, uniqueId }) {
                return (
                  <>
                    <div>{nickName}</div>
                    <div>({uniqueId})</div>
                  </>
                );
              },
            }),
            getTableColumn({
              title: dict('SOURCE_TIKTOK_ACCOUNT'),
              dataIndex: 'referName',
            }),

            getTableColumn({
              title: dict('CITY_AREA'),
              dataIndex: 'cityName',
            }),
            getTableColumn({
              title: dict('LATEST_CLUE'),
              dataIndex: 'telephone',
            }),
            getTableColumn({
              title: dict('LAST_ACTION_TIME'),
              dataIndex: 'actionTime',
              sorter: true,
              render: (value) => DateUtils.formatDateTime(value),
            }),

            getTableColumn({
              title: dict('INTERACTION_TYPE'),
              dataIndex: 'actionTypeName',
            }),
            getTableColumn({
              title: dict('COMMENT_COUNT'),
              dataIndex: 'commentCount',
              align: 'right',
              placeholder: '0',
            }),

            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 80,
              render: (_, record: any) => {
                const { id } = record;
                return (
                  <Space>
                    <Link
                      onClick={() => navigate(`./${ROUTE_PATH.DETAIL}/${id}`)}
                      {...getBtnPermissionConfig(AUTH.CUSTOMER_HIGH_POTENTIAL_USER.VIEW)}
                    >
                      {dict('ACTION_VIEW')}
                    </Link>
                  </Space>
                );
              },
            }),
          ]}
        />
      </ProCard>
    </Container>
  );
};
export default List;
