import React from 'react';
import { WordCloud, WordCloudConfig } from '@ant-design/plots';
import { isEmpty } from 'lodash';
import { Empty } from '@antd';
import { HomeUserAnalysisResult } from '@/services/home/type';

export const FunsWordCloud = ({ data }: { data?: HomeUserAnalysisResult[] }) => {
  const config: WordCloudConfig = {
    data: (data || [])
      .filter((v) => v.audienceCharacterType == 'integererest')
      .map((v) => ({ name: v.dimensions, value: v.metrics })),
    wordField: 'name',
    weightField: 'value',
    colorField: 'name',
    tooltip: {
      formatter: (datum) => ({
        name: datum.text,
        value: `${datum.value}%`,
      }),
    },
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [8, 32],
      rotation: 0,
    },

    // 返回值设置成一个 [0, 1) 区间内的值，
    // 可以让每次渲染的位置相同（前提是每次的宽高一致）。
    random: () => 0.5,
  };

  return isEmpty(data) ? (
    <div style={{ paddingTop: 40 }}>
      <Empty />
    </div>
  ) : (
    <div style={{ height: 300 }}>
      <WordCloud {...config} />
    </div>
  );
};
