import { ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { Validator } from '@/utils/validator';
import { Menu, MenuProps, Space, Tabs } from '@antd';
import { useCreation, useDebounceEffect, useSafeState } from 'ahooks';
import { omit } from 'lodash';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'umi';
import UserOperation from '../UserOperation';
import TenantSelect from './TenantSelect';
import style from './index.less';
import { getRedirectPath, selectedMenu } from '@/layouts/const/config.var';

const { isNilEmpty } = Validator;

type PageHeaderProps = {
  menus?: MenuProps['items'];
  showMenu?: boolean;
};

export const PageHeader: React.FC<PageHeaderProps> = ({ menus, showMenu = true }) => {
  const nav = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const [tenantId, setTenantId] = useSafeState('');

  const { setTenant } = useAppContext();
  const selectMenu = useCreation(() => selectedMenu(pathname, menus), [pathname, menus]);

  const selectRootMenu = useCreation(() => {
    const rootMenu: any = menus?.find((item: any) => item.children?.includes(selectMenu));
    return rootMenu ?? selectMenu;
  }, [menus, selectMenu]);

  const rootMenus = useCreation(() => {
    return menus?.map((item: any) => {
      return {
        ...omit(item, ['children']),
      } as any;
    });
  }, [menus]);

  const slibingMenus = useCreation(() => {
    if (!selectMenu) return null;
    const parentMenu: any = menus?.find((item: any) => item.children?.includes(selectMenu));
    return parentMenu?.children;
  }, [menus, selectMenu]);

  // 重定向
  useDebounceEffect(
    () => {
      if (isNilEmpty(selectMenu) && !isNilEmpty(menus)) {
        const redirectPath = getRedirectPath(pathname, menus);
        if (redirectPath) nav(redirectPath, { replace: true });
      }
    },
    [selectMenu, menus, pathname],
    { wait: 10 },
  );

  const RightOperation = (
    <div
      style={{
        minWidth: 200,
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      <Space>
        <TenantSelect
          className={style['tenant-select']}
          size="middle"
          bordered={false}
          showSearch={false}
          checkFirst
          popupMatchSelectWidth={120}
          value={tenantId}
          onChange={(v, option) => {
            setTenantId(v);
            setTenant(option as any);
            if (tenantId) {
              //非列表页面返回上级路由
              if (params.id || pathname.endsWith(ROUTE_PATH.CREATE)) {
                nav(-1);
              }
              setTimeout(() => window.location.reload(), 100);
            }
          }}
        />
        <UserOperation />
      </Space>
    </div>
  );

  return showMenu ? (
    <div className={style['page-header']}>
      <div className={style['page-header-first']}>
        <div style={{ minWidth: 650 }}>
          {!isNilEmpty(rootMenus) && (
            <Menu
              onClick={(e) => {
                nav(e.key);
              }}
              selectedKeys={[`${selectRootMenu?.key}`]}
              mode="horizontal"
              items={rootMenus}
            />
          )}
        </div>
        {RightOperation}
      </div>
      {!isNilEmpty(slibingMenus) && (
        <div className={style['page-header-second']}>
          <Tabs size="small" activeKey={selectMenu?.key} items={slibingMenus} onTabClick={(key) => nav(key)} />
        </div>
      )}
    </div>
  ) : (
    RightOperation
  );
};

export default PageHeader;
