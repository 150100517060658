import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { ROUTE_PATH } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/live/const.var';
import { DateUtils } from '@/utils/dateUtil';
import { Space, Spin, Table, Typography } from '@antd';
import { useCreation, useRequest, useSafeState } from 'ahooks';
import { camelCase } from 'lodash-es';
import { useNavigate } from 'umi';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { downloadDyLiveStream, getDyLiveStreamPages } from '@/services/live_stream/dy';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

const { Link } = Typography;

type ListPros = {
  params: any;
};
const List = ({ params }: ListPros) => {
  const navigate = useNavigate();
  const { getEnum } = useAppContext();
  const [downloadParams, setDownloadParams] = useSafeState<any>({});
  const liveStreamSortEnums = getEnum('LiveStreamSortParamEnum');
  const liveStreamSortFieldMap = useCreation(
    () =>
      liveStreamSortEnums.reduce((map, cur) => {
        const { name } = cur;
        const [, fieldName] = name.split('.');
        map[camelCase(fieldName)] = name;
        return map;
      }, {}),
    [liveStreamSortEnums],
  );

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadDyLiveStream(downloadParams), {
    manual: true,
  });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'liveStartTime', order: 'descend' } }) => {
      const downloadParams = { ...params, ...getSoretParams(sorter, liveStreamSortFieldMap) };
      setDownloadParams(downloadParams);
      return getDyLiveStreamPages({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 500 },
  );

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW);
  const ROUTE_REPORT_PATH = `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}`;

  return (
    <FormCard
      title={dict('LIVE_DATA')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 2600 }}
        columns={
          [
            // getTableColumn({
            //   title: dict('COVER'),
            //   dataIndex: 'replayOssUrl',
            //   fixed: 'left',
            //   render: (url) => <LiveVideoCoverImg style={{ width: 80 }} src={url} />,
            // }),
            getTableColumn({ title: dict('LIVE_ROOM_ID'), dataIndex: 'roomId' }),
            getTableColumn({ title: dict('LIVE_COVER_TITLE'), dataIndex: 'liveStreamCoverTitle' }),
            getTableColumn({
              title: dict('LIVE_ACCOUNT'),
              dataIndex: 'name',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatarUrl} name={record?.name} />,
            }),
            getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
            getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
            getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
            getTableColumn({
              title: dict('LIVE_TIME'),
              dataIndex: 'liveStartTime',
              sorter: true,
              defaultSortOrder: 'descend',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('LIVE_END_TIME'),
              dataIndex: 'liveEndTime',
              sorter: true,
              render: (v) => DateUtils.formatDateTime(v),
            }),

            getTableColumn({
              title: dict('LIVE_DURATION'),
              dataIndex: 'liveDuration',
              sorter: true,
              render: (v) => DateUtils.formatDiffrenceTime(v),
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, //累计观看人数
              dataIndex: 'lpScreenLiveWatchUv',
              width: 120,
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />, //最高在线人数
              dataIndex: 'lpScreenLiveMaxWatchUvByMinute',
              width: 120,
              sorter: true,
            }),

            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />, // 人均观看时长
              dataIndex: 'lpScreenLiveAvgWatchDuration',
              sorter: true,
              width: 120,
              render: (v) => DateUtils.formatDiffrenceTime(v),
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveFollowUv" />, //涨粉量
              dataIndex: 'lpScreenLiveFollowUv',
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />, //评论人数
              dataIndex: 'lpScreenLiveCommentUv',
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenMsgConversationCount" />, //私信人数
              dataIndex: 'lpScreenMsgConversationCount',
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveClueBusinessCardClickCount" />, //卡片点击次数
              dataIndex: 'lpScreenLiveClueBusinessCardClickCount',
              width: 120,
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenClueUv" />, //全场景线索人数
              dataIndex: 'lpScreenClueUv',
              width: 140,
              sorter: true,
            }),

            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 160,
              render: (_, record: any) => {
                const { roomId } = record;
                const url = `${ROUTE_REPORT_PATH}/${ROUTE_PATH.DOUYIN}/${ROUTE_PATH.DETAIL}/${roomId}`;

                return (
                  <Space>
                    <Link onClick={() => navigate(`${url}?type=data`)} {...viewAuthConfig}>
                      {dict('ACTION_REPORT')}
                    </Link>
                    <Link onClick={() => navigate(`${url}?type=barrage`)} {...viewAuthConfig}>
                      {dict('BARRAGE')}
                    </Link>
                    <Link onClick={() => navigate(`${url}?type=playhistory`)} {...viewAuthConfig}>
                      {dict('ACTION_REPLAY')}
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as any[]
        }
      />
    </FormCard>
  );
};

export default List;
