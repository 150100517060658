import { useAppContext } from '@/contexts/AppContext';
import useTableRequest from '@/hooks/useTableRequest';
import { cluesDownload, getCluesPage } from '@/services/clues/douyin';
import { ProCard } from '@ant-design/pro-components';
import { Button, Table } from '@antd';
import { Space, Typography } from '@antd/Antd';
import { useBoolean, useRequest, useSafeState } from 'ahooks';
import { snakeCase } from 'lodash';
import { CluesPageParam } from '@/services/clues/douyin/type';
import { ROUTE_PATH } from '@/constants/const';
import { getLeadsClueDynamicColumn, saveDynamicColumnToLs } from '@/constants/dynamic-column/leads/clue/douyin';
import { DyColumnGroup } from '@/components/CustomColumnModal/type';
import CustomColumnModal from '@/components/CustomColumnModal';
import { columnPlaceholder, convertToTableColumns } from '@/constants/dynamic-column/';
import type { ColumnType } from 'antd/es/table';
import { useNavigate } from 'umi';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';
const { Link } = Typography;

const List = ({ searchParams }: { searchParams: CluesPageParam }) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [queryParams, setQueryParams] = useSafeState<any>({});
  // {dict('CUSTOM_COLUMNS')}
  const [tableColumnsConfig, setTableColumnsConfig] = useSafeState(getLeadsClueDynamicColumn(true));
  const [customColumnVisible, { setTrue: showCustomColumn, setFalse: hideCustomColumn }] = useBoolean(false);

  const { run: download, loading: downloading } = useRequest(() => cluesDownload(queryParams), { manual: true });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const sortParams: any = {};

      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = snakeCase(sorter.field);
        sortParams.direction = { ascend: 'asc', descend: 'desc' }[sorter.order];
      }

      const query = {
        ...searchParams,
        ...sortParams,
        bid: user?.bid,
      };
      setQueryParams(query);
      return getCluesPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [searchParams, user], debounceWait: 100 },
  );
  const columns = convertToTableColumns(
    tableColumnsConfig,
    [
      {
        dataIndex: 'operation',
        fixed: 'right',
        width: 80,
        render(_, record) {
          return <a onClick={() => navigate(`./${ROUTE_PATH.DETAIL}/${record.id}`)}>{dict('ACTION_VIEW')}</a>;
        },
      },
    ],
    { width: 150 },
  )
    .map((column): ColumnType<any> => {
      if (column.key == 'name') {
        return {
          ...column,
          render: (val, record) => {
            return (
              <>
                {val || '-'}
                {/*<br />*/}
                {/*{record.uniqueId && `(${record.uniqueId})`}*/}
              </>
            );
          },
        };
      }
      if (column.key == 'accountUniqueName') {
        return {
          ...column,
          render: (val, record) => {
            return (
              <>
                {val || '-'}
                <br />
                {record.accountUniqueId && `(${record.accountUniqueId})`}
              </>
            );
          },
        };
      }
      if (column.key == 'clueId') {
        return {
          ...column,
          width: 180,
        };
      }
      if (column.key == 'operation') {
        return {
          ...column,
          fixed: 'right',
          width: 100,
          render(value, record, index) {
            return (
              <Link
                onClick={() => navigate(`./${ROUTE_PATH.DETAIL}/${record.id}?clueId=${record.clueId}`)}
                {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.VIEW)}
              >
                {dict('ACTION_VIEW')}
              </Link>
            );
          },
        };
      }
      if (column.key == 'createTime') {
        return {
          ...column,
          width: 150,
          sorter: true,
        };
      }
      if (column.key == 'lastActionTimeMs') {
        return {
          ...column,
          width: 150,
          sorter: true,
        };
      }
      return {
        ...column,
      };
    })
    .map(
      columnPlaceholder({
        '0': ['leftLeadsNumber'],
      }),
    );

  const { getBtnPermissionConfig } = usePermission();

  return (
    <>
      <ProCard>
        <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'space-between' }}>
          <Button
            loading={downloading}
            type="primary"
            onClick={download}
            {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.DOWNLOAD)}
          >
            {dict('ACTION_DOWNLOAD_DATA')}
          </Button>
          <a onClick={showCustomColumn}>{dict('CUSTOM_COLUMNS')}</a>
        </Space>
        <Table {...tableProps} rowKey="id" scroll={{ x: columns.length * 120 }} columns={columns} />
      </ProCard>
      <CustomColumnModal
        visible={customColumnVisible}
        tableColumnsConfig={tableColumnsConfig}
        defaultTableColumnsConfig={getLeadsClueDynamicColumn(true)}
        onCancel={hideCustomColumn}
        onConfigOK={(config: DyColumnGroup[]) => {
          saveDynamicColumnToLs(config);
          setTableColumnsConfig(config);
          hideCustomColumn();
        }}
      />
    </>
  );
};
export default List;
