import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { cluesDownload, getCluesPage } from '@/services/clues/all';
import { ProCard } from '@ant-design/pro-components';
import { Button, Table, Typography } from '@antd';
import { Space } from '@antd/Antd';
import { useRequest, useSafeState } from 'ahooks';
import { isNil, snakeCase } from 'lodash-es';
import { CluesPageParam } from '@/services/clues/all/type';
import { MEDIA_ID_MAPPING, ROUTE_PATH } from '@/constants/const';
import { useNavigate } from 'umi';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

const { Link } = Typography;

const List = ({ searchParams }: { searchParams: CluesPageParam }) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [queryParams, setQueryParams] = useSafeState<any>({});

  const { run: download, loading: downloading } = useRequest(() => cluesDownload(queryParams), { manual: true });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter }) => {
      const sortParams: any = {};
      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = snakeCase(sorter.field);
        sortParams.direction = { ascend: 'asc', descend: 'desc' }[sorter.order];
      }

      const query = {
        ...searchParams,
        ...sortParams,
        bid: user?.bid,
      };
      setQueryParams(query);
      return getCluesPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [searchParams, user], debounceWait: 100 },
  );

  const { getBtnPermissionConfig } = usePermission();

  return (
    <ProCard>
      <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'space-between' }}>
        <Button
          loading={downloading}
          type="primary"
          {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.DOWNLOAD)}
          onClick={download}
        >
          {dict('ACTION_DOWNLOAD_DATA')}
        </Button>
      </Space>
      <Table
        {...tableProps}
        rowKey="id"
        columns={[
          getTableColumn({ title: dict('LEAD_ID'), dataIndex: 'clueId', width: 138 }),
          getTableColumn({ title: dict('AFFILIATION_PLATFORM'), dataIndex: 'mediaName' }),
          getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
          getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
          getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
          getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
          getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
          getTableColumn({ title: dict('ACCOUNT'), dataIndex: 'accountName', width: 150 }),
          getTableColumn({
            title: dict('NAME'),
            dataIndex: 'name',
            width: 160,
          }),
          getTableColumn({ title: dict('TELEPHONE'), dataIndex: 'phone' }),
          getTableColumn({ title: dict('RERA'), dataIndex: 'region' }),
          getTableColumn({ title: dict('WECHAT'), dataIndex: 'wechat' }),
          getTableColumn({ title: dict('AD_CAMPAIGN_ID'), dataIndex: 'campaignId' }),
          getTableColumn({ title: dict('AD_CAMPAIGN_NAME'), dataIndex: 'campaignName', width: 180 }),
          getTableColumn({ title: dict('CLUE_CRATE_TIME'), dataIndex: 'createTime', width: 150 }),
          getTableColumn({
            title: dict('ACTION_OPERATE'),
            dataIndex: 'operation',
            fixed: 'right',
            width: 80,
            render(_, record) {
              const mediaKey = MEDIA_ID_MAPPING[record.mediaId];
              return (
                <Link
                  disabled={isNil(mediaKey)}
                  onClick={() => navigate(`./../${mediaKey}/${ROUTE_PATH.DETAIL}/${record.id}?clueId=${record.clueId}`)}
                  {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.VIEW)}
                >
                  {dict('ACTION_VIEW')}
                </Link>
              );
            },
          }),
        ]}
      />
    </ProCard>
  );
};
export default List;
