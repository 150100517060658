import { Blank, FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { LiveLineChart as DouyinLiveLineTrendChart } from '@/pages/live/report/douyin/components/detail/Data/data-chart/LineChart';
import { StatisticCardSimple as DouyinStatisticCard } from '@/pages/live/report/douyin/components/list/DataStatisticCard';
import { StatisticCardSimple as KuaishouStatisticCard } from '@/pages/live/report/kuaishou/components/list/DataStatisticCard';
import { LiveLineChart as WeixinLiveLineTrendChart } from '@/pages/live/report/weixin/components/detail/Data/data-chart/LineChart';
import WeixinLiveList from '@/pages/live/report/weixin/components/list/DataList';
import DouyinLiveList from '@/pages/live/report/douyin/components/list/DataList';
import KuaiShouLiveList from '@/pages/live/report/kuaishou/components/list/DataList';

import { StatisticCardSimple as WeixinStatisticCard } from '@/pages/live/report/weixin/components/list/DataStatisticCard';
import { DateUtils } from '@/utils/dateUtil';
import { useCreation, useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { Key } from 'react';
import { KuaiShouAccountReportLiveLineChart } from './chart/KuaishouLiveTrendChart';
import DateRangePicker from '@/components/TableFilter/RangePicker';
import { Space } from '@antd';
import { ProCard } from '@ant-design/pro-components';
import { dict } from '@/hooks/useChangeLocale';
type ContentProps = {
  accountId?: Key;
  rangeDate?: any;
  mediaId: MEDIA_KEY_ENUM;
};

const today = dayjs();
const before30Day = today.subtract(30, 'day');
const initSearchParams = {
  roomId: '',
  liveTime: DateUtils.convertRangeStr([before30Day, today], true),
};

//平台组件Mapping
const mediaComponentsMap = {
  [MEDIA_KEY_ENUM.DOUYIN]: {
    StatisticCard: DouyinStatisticCard,
    LineChart: DouyinLiveLineTrendChart,
    List: DouyinLiveList,
  },
  [MEDIA_KEY_ENUM.KUAISHOU]: {
    StatisticCard: KuaishouStatisticCard,
    LineChart: KuaiShouAccountReportLiveLineChart,
    List: KuaiShouLiveList,
  },
  [MEDIA_KEY_ENUM.WEIXIN]: {
    StatisticCard: WeixinStatisticCard,
    LineChart: WeixinLiveLineTrendChart,
    List: WeixinLiveList,
  },
};

export default (props: ContentProps) => {
  const { user } = useAppContext();
  const { mediaId, accountId } = props;
  const { StatisticCard, LineChart, List } = mediaComponentsMap[mediaId];
  const [baseParams, setBaseParams] = useSafeState<any>(initSearchParams);
  const params = useCreation(() => {
    const { liveTime, ...restParams } = baseParams;
    const [liveTimeStart, liveTimeEnd] = DateUtils.convertDateTimeRangeStr(liveTime);
    return { ...restParams, liveTimeStart, liveTimeEnd, bid: user?.bid, accountIds: [accountId] };
  }, [baseParams, user, accountId]);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Space>
          {dict('LIVE_TIME')}
          <DateRangePicker
            value={baseParams.liveTime.map(dayjs)}
            showTime
            onChange={(liveTime) => setBaseParams((prev) => ({ ...prev, liveTime }))}
          />
        </Space>
      </div>

      <Blank />
      <FormCard title={dict('DATA_INDICATORS')} bordered boxShadow={false}>
        <StatisticCard searchPrams={{ bid: user?.bid, accountId: props.accountId }} />
      </FormCard>
      <Blank />
      <FormCard title={dict('TREND_ANALYSIS')} bordered boxShadow={false}>
        <LineChart accountId={props.accountId} startDate={params.liveTimeStart} endDate={params.liveTimeEnd} />
      </FormCard>
      <Blank />
      <ProCard.Group boxShadow={false}>
        <List params={params} />
      </ProCard.Group>
    </>
  );
};
