import { useAppContext } from '@/contexts/AppContext';
import { getUserBid } from '@/services/user';
import { Validator } from '@/utils/validator';
import { Select, SelectProps } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { useEffect } from 'react';

const EnterpriseSelect: React.FC<SelectProps & { checkFirst?: boolean }> = ({ checkFirst, ...props }) => {
  const { user } = useAppContext();

  const { data: list, loading } = useRequest(
    async () => {
      if (!user) return [];
      return getUserBid(user.loginId);
    },
    { refreshDeps: [user?.loginId] },
  );

  const options = useCreation(() => {
    return list?.map((item) => ({
      ...item,
      value: item.id,
      label: item.displayName,
    }));
  }, [list]);

  useEffect(() => {
    if (Validator.isNilEmpty(list) || !checkFirst) return;
    const tenantId = user?.bid || list[0].id;
    props?.onChange?.(tenantId, list.find((v) => v.id == tenantId) as any);
  }, [list, checkFirst]);

  return (
    <Select
      {...props}
      loading={loading}
      options={options}
      onChange={(value, option) => {
        props?.onChange?.(value, option);
      }}
    />
  );
};

export default EnterpriseSelect;
