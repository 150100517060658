import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';
import { CHANNEL_COUNT_TYPE, channelCountOptions } from '@/pages/live/report/weixin/detail/const.var';
import { getWxLiveStreamDataDetail, getWxLiveStreamTotalTrend, getWxLiveWatchProfile } from '@/services/live_stream/wx';
import { ProCard } from '@ant-design/pro-components';
import { Radio, Spin } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import DataStatisticCard from './DataStatisticCard';
import ChannelChart from './data-chart/ChannelChart';
import LineChart from './data-chart/LineChart';
import CommonPieChart, { getAgeLabel } from './watch-profile-chart/CommonPieChart';
import RankBar from './watch-profile-chart/RankBar';

type DataProps = {
  roomId: string;
};

// 获取指定类型的画像数据
const getProfileData = (data: any[] = [], type) => {
  return data
    .filter((item) => item.type == type)
    .map((item) => ({
      name: item.typeVal,
      value: +item.dataVal,
    }));
};

const Data: React.FC<DataProps> = ({ roomId }) => {
  const [channelCountType, setChannelCountType] = useSafeState(CHANNEL_COUNT_TYPE.CUMULATIVE_WATCH_COUNT);
  const { data, loading } = useRequest(
    () => {
      return Promise.all([
        getWxLiveStreamDataDetail(roomId),
        getWxLiveStreamTotalTrend({
          roomId,
          timeRange: 6,
        }),
        getWxLiveWatchProfile(roomId),
      ]);
    },
    { refreshDeps: [roomId] },
  );

  const [streamData, totalTrendData, profileData] = data || [];
  // 2 年龄 3 性别 4 地域 5 粉丝
  const formatProfileData = JSON.parse(profileData || '[]');

  return (
    <Spin spinning={loading}>
      <DataStatisticCard liveData={streamData} totalTrendData={totalTrendData} />

      <ProCard.Group gutter={[16, 16]} wrap>
        <ProCard title={dict('TREND_ANALYSIS')} {...headerFormCardProps} colSpan={24}>
          <LineChart roomId={roomId} />
        </ProCard>
        <ProCard title={dict('GENDER_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <CommonPieChart liveStreamData={streamData} data={getProfileData(formatProfileData, 3)} />
        </ProCard>

        <ProCard title={dict('FAN_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <CommonPieChart liveStreamData={streamData} data={getProfileData(formatProfileData, 5)} />
        </ProCard>

        <ProCard title={dict('AGE_DISTRIBUTION')} {...headerFormCardProps} colSpan={12}>
          <CommonPieChart
            liveStreamData={streamData}
            data={getProfileData(formatProfileData, 2)}
            formatName={getAgeLabel}
          />
        </ProCard>
        <ProCard title={dict('REGION_AREA_TOP_5')} {...headerFormCardProps} colSpan={12}>
          <RankBar data={getProfileData(formatProfileData, 4)} />
        </ProCard>
        <ProCard
          title={dict('CHANNEL_DISTRIBUTION')}
          {...headerFormCardProps}
          colSpan={12}
          extra={
            <Radio.Group
              size="small"
              optionType="button"
              buttonStyle="solid"
              value={channelCountType}
              options={channelCountOptions}
              onChange={(e) => setChannelCountType(e.target.value)}
            />
          }
        >
          <ChannelChart liveStreamData={streamData} type={channelCountType} roomId={roomId} />
        </ProCard>
      </ProCard.Group>
    </Spin>
  );
};

export default Data;
