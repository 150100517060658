import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { ROUTE_PATH } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/live/const.var';
import { LiveIndicatorTitle, indicatorMap } from '@/pages/live/report/components/LiveIndicatorTitle';
import { downloadWxLiveStream, getWxLiveStreamPages } from '@/services/live_stream/wx';
import { DateUtils } from '@/utils/dateUtil';
import { Space, Spin, Table, Typography } from '@antd';
import { useCreation, useRequest, useSafeState } from 'ahooks';
import { camelCase } from 'lodash-es';
import { useNavigate } from 'umi';

const { Link } = Typography;

type ListPros = {
  params: any;
};
const List = ({ params }: ListPros) => {
  const navigate = useNavigate();
  const { getEnum } = useAppContext();
  const [downloadParams, setDownloadParams] = useSafeState<any>({});
  const liveStreamSortEnums = getEnum('LiveStreamSortParamEnum');
  const liveStreamSortFieldMap = useCreation(
    () =>
      liveStreamSortEnums.reduce((map, cur) => {
        const { name } = cur;
        const [, fieldName] = name.split('.');
        map[camelCase(fieldName)] = name;
        return map;
      }, {}),
    [liveStreamSortEnums],
  );

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadWxLiveStream(downloadParams), {
    manual: true,
  });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'liveStartTime', order: 'descend' } }) => {
      const downloadParams = { ...params, ...getSoretParams(sorter, liveStreamSortFieldMap) };
      setDownloadParams(downloadParams);
      return getWxLiveStreamPages({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 100 },
  );

  const { getBtnPermissionConfig } = usePermission();
  const viewAuthConfig = getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW);

  const ROUTE_REPORT_PATH = `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}`;

  return (
    <FormCard
      title={dict('LIVE_DATA')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 3200 }}
        columns={[
          getTableColumn({ title: dict('LIVE_ROOM_ID'), dataIndex: 'roomId' }),
          getTableColumn({
            title: dict('LIVE_ACCOUNT'),
            dataIndex: 'name',
            width: 150,
            render: (_, record) => <AccountAvator avaterUrl={record?.avatarUrl} name={record?.name} />,
          }),
          getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
          getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
          getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
          getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
          getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
          getTableColumn({ title: dict('LIVE_STREAM_THEME'), dataIndex: 'liveStreamCoverTitle', width: 150 }),
          getTableColumn({
            title: dict('LIVE_TIME'),
            dataIndex: 'liveStartTime',
            sorter: true,
            defaultSortOrder: 'descend',
            render: (v) => DateUtils.formatDateTime(v),
          }),
          getTableColumn({
            title: dict('LIVE_END_TIME'),
            dataIndex: 'liveEndTime',
            sorter: true,
            render: (v) => DateUtils.formatDateTime(v),
          }),
          getTableColumn({
            title: dict('LIVE_DURATION'),
            dataIndex: 'liveDuration',
            sorter: true,
            render: (v) => DateUtils.formatDiffrenceTime(v),
          }),
          // 累积观看人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />,
            dataIndex: 'lpScreenLiveWatchUv',
            width: 150,
            sorter: true,
          }),
          // 实时在线人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveUserRealtime" />,
            dataIndex: 'lpScreenLiveUserRealtime',
            width: 150,
            sorter: true,
          }),
          // 最高在线人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />,
            dataIndex: 'lpScreenLiveMaxWatchUvByMinute',
            width: 150,
            sorter: true,
          }),
          // 人均观看时长
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />,
            dataIndex: 'lpScreenLiveAvgWatchDuration',
            width: 150,
            sorter: true,
            render: (v) => DateUtils.formatDiffrenceTime(v),
          }),
          // 评论次数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />,
            dataIndex: 'lpScreenLiveCommentUv',
            width: 150,
            sorter: true,
          }),
          // 评论次数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />,
            width: 150,
            dataIndex: 'lpScreenLiveCommentCount',
            sorter: true,
          }),
          // 点赞次数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />,
            dataIndex: 'lpScreenLiveLikeCount',
            width: 150,
            sorter: true,
          }),
          // 新增关注
          getTableColumn({
            title: <LiveIndicatorTitle title={dict('ADD_FOLLOW')} tip={indicatorMap.lpScreenLiveFollowUv.tip} />,
            dataIndex: 'cumulativeNewFollowUv',
            width: 150,
            sorter: true,
          }),
          // 分享直播间人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveShareUv" />,
            width: 150,
            dataIndex: 'lpScreenLiveShareUv',
            sorter: true,
          }),
          // 分享直播间次数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveShareCount" />,
            dataIndex: 'lpScreenLiveShareCount',
            width: 150,
            sorter: true,
          }),
          // 加入粉丝团人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUv" />,
            dataIndex: 'lpScreenLiveFansClubJoinUv',
            width: 150,
            sorter: true,
          }),
          getTableColumn({
            title: dict('TOTAL_HEAT'),
            dataIndex: 'cumulativeHotQuota',
            sorter: true,
          }),
          getTableColumn({
            title: dict('GIFT_GIVE_USER_COUNT'),
            dataIndex: 'cumulativeRewardUv',
            sorter: true,
          }),
          getTableColumn({
            title: dict('GIFT_GIVE_HEAT'),
            dataIndex: 'cumulativeRewardAmountInHeatAnchor',
            sorter: true,
          }),
          // 平均在线人数
          getTableColumn({
            title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />,
            dataIndex: 'averageOnlineWatchUv',
            width: 150,
            sorter: true,
          }),
          getTableColumn({
            title: dict('ENTER_ROOM_USER_COUNT'),
            dataIndex: 'newWatchUv',
            sorter: true,
          }),
          getTableColumn({ title: dict('LEAVE_ROOM_USER_COUNT'), dataIndex: 'leavedWatchUv', sorter: true }),
          getTableColumn({ title: dict('EXPOSURE_UV'), dataIndex: 'exposureUv', width: 150, sorter: true }),
          getTableColumn({ title: dict('EXPOSURE_CLICK_UV'), dataIndex: 'clickUv', width: 150, sorter: true }),
          getTableColumn({
            title: dict('EXPOSURE_CONVISION_RATE'),
            dataIndex: 'conversionRate',
            width: 150,
            sorter: true,
            render: (v) => `${v}%`,
          }),
          getTableColumn({ title: dict('COLLECTED_LEAD_NUM'), dataIndex: 'leadsNum', width: 150, sorter: true }),
          getTableColumn({
            title: dict('ACTION_OPERATE'),
            align: 'center',
            fixed: 'right',
            width: 160,
            render: (_, record: any) => {
              const { roomId } = record;
              const url = `${ROUTE_REPORT_PATH}/${ROUTE_PATH.SHIPINHAO}/${ROUTE_PATH.DETAIL}/${roomId}`;

              return (
                <Space>
                  <Link onClick={() => navigate(`${url}?type=data`)} {...viewAuthConfig}>
                    {dict('ACTION_REPORT')}
                  </Link>
                  <Link onClick={() => navigate(`${url}?type=barrage`)} {...viewAuthConfig}>
                    {dict('BARRAGE')}
                  </Link>
                  <Link onClick={() => navigate(`${url}?type=playhistory`)} {...viewAuthConfig}>
                    {dict('ACTION_REPLAY')}
                  </Link>
                </Space>
              );
            },
          }),
        ]}
      />
    </FormCard>
  );
};

export default List;
