import ChartCore from '@/components/ChartCore';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { themeConfig } from '@/layouts/const/themes';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { generate } from '@ant-design/colors';
import { max } from 'lodash';

const Chart = ({ liveConvertVO }: { liveConvertVO: any }) => {
  const { getEnum } = useAppContext();

  const maxValue = max(Object.values(liveConvertVO || {})) as number;
  const options = {
    series: [
      {
        type: 'funnel',
        min: 0,
        max: 100,
        // minSize: '55%',
        maxSize: '100%',

        sort: 'descending',
        // colorBy: 'series',
        color: generate(themeConfig.colorPrimary),
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return `${params.data.name} ${params.data.value} ${dict('PEOPLE')} (${params.data.conversionRate})`;
          },
        },
        data: Object.entries(liveConvertVO || {})
          .map(([name, value]) => ({
            value,
            name: Format.toArrLabel(getEnum('LiveConvertTypeEnum'), name, 'name', 'displayName'),
            conversionRate: maxValue ? Format.toPercent(Number(value) / maxValue) : 0,
          }))
          .sort((v1: any, v2: any) => v1.value - v2.value),
      },
    ],
  };

  return (
    <ChartCore
      empty={Validator.isNilEmpty(liveConvertVO)}
      option={options}
      notMerge
      style={{ height: 300, width: '100%', display: 'inline-block' }}
    />
  );
};

export default Chart;
