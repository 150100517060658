import { LiveDataVO } from '@/services/live_stream/all/type';
import React from 'react';

import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { ProCard } from '@ant-design/pro-components';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { dict } from '@/hooks/useChangeLocale';
type DataCardProps = {
  liveDataVO?: LiveDataVO;
};
export const DataCard: React.FC<DataCardProps> = ({ liveDataVO }) => {
  const loading = !liveDataVO;
  const item1: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, //累计观看人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
      otherLayoutDirection: 'horizontal',
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />, //最高在线人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveMaxWatchUvByMinute),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />, //人均观看时长
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveAvgWatchDuration),
          suffix: dict('STATIST_UNIT_SEC'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />, //平均在线人数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveAvgOnlineUvByRoom),
          suffix: dict('STATIST_UNIT_REN'),
        },
      ],
    },
  ];

  const item2: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveInteractionCount" />, //互动次数
      value: Format.toAmountNumber(liveDataVO?.lpScreenLiveInteractionCount),
      suffix: dict('STATIST_UNIT_CI'),
      otherLayoutDirection: 'horizontal',
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />, //点赞次数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveLikeCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />, //评论次数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveCommentCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
        {
          title: <LiveIndicatorTitle type="lpScreenLiveShareCount" />, //分享次数
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveShareCount),
          suffix: dict('STATIST_UNIT_CI'),
        },
      ],
    },
  ];

  const item3: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenClueUv" />, //全场景线索人数
      value: Format.toAmountNumber(liveDataVO?.lpScreenClueUv),
      suffix: dict('STATIST_UNIT_REN'),
      otherLayoutDirection: 'horizontal',
      other: [
        {
          title: <LiveIndicatorTitle type="lpScreenLiveClueConvertRatio" />, //线索转化率
          value: Format.toAmountNumber(liveDataVO?.lpScreenLiveClueConvertRatio, 2),
          suffix: '%',
        },
        {
          title: <LiveIndicatorTitle type="lpScreenClueCost" />, //线索成本
          value: Format.toAmountNumber(liveDataVO?.lpScreenClueCost),
          suffix: dict('STATIST_UNIT_YUAN'),
        },
      ],
    },
  ];
  return (
    <>
      <span>{dict('DATA_VIEW')}</span>
      <div>
        <ProCard.Group direction="row" loading={loading}>
          <ProCard>
            <StatisticCardPanel loading={loading} items={item1} />
          </ProCard>
          <ProCard.Divider type="vertical" />
          <ProCard>
            <StatisticCardPanel loading={loading} items={item2} />
          </ProCard>
          <ProCard.Divider type="vertical" />
          <ProCard>
            <StatisticCardPanel loading={loading} items={item3} />
          </ProCard>
        </ProCard.Group>
      </div>
    </>
  );
};
