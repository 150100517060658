import { getLiveStreamDataDetail, getLiveStreamDetailHight } from '@/services/live_stream/all';
import { LiveStreamData } from '@/services/live_stream/all/type';
import { Alert, Tabs } from '@antd';
import { useEventEmitter, useRequest } from 'ahooks';
import { DataCard } from './DataCard';
import LineChart from './LineChart';
import { DanmuList, HightLightTimeList } from './List';
import { VideoPlay } from './VideoPlay';
import style from './index.less';
import { dict } from '@/hooks/useChangeLocale';

type PlayHistoryProps = {
  roomId: string;
  detail?: LiveStreamData;
};

export type VideoEventType = {
  action: 'updatePlayTime' | 'updateVideoPlayTime';
  value: any;
};
const PlayHistory: React.FC<PlayHistoryProps> = ({ roomId, detail }) => {
  const { data } = useRequest(() => getLiveStreamDataDetail(roomId), { refreshDeps: [roomId] });
  const videoEvent$ = useEventEmitter<VideoEventType>();

  //加载高光时刻
  const { data: highlightList } = useRequest(() => getLiveStreamDetailHight(roomId), {
    refreshDeps: [roomId],
  });

  return (
    <>
      <Alert
        message={dict('LIVE_DETAIL_PLAY_TIP')}
        style={{ border: 0, background: 'none', marginTop: -10 }}
        showIcon
        closable={false}
      />

      <div className={style.palyWrapPanel}>
        <div>
          <VideoPlay roomId={roomId} detail={detail} event$={videoEvent$} />
          <Tabs
            style={{ width: 300 }}
            items={[
              {
                key: 'live-danmu',
                label: dict('COMMENT'),
                children: <DanmuList roomId={roomId} detail={detail} event$={videoEvent$} />,
              },
              {
                key: 'high-light-time',
                label: dict('LIVE_DETAIL_TAB_HIGH_LIGHT'),
                children: <HightLightTimeList list={highlightList} detail={detail} event$={videoEvent$} />,
              },
            ]}
          />
        </div>
        <div>
          <DataCard liveDataVO={data?.liveDataVO} />
          <LineChart roomId={roomId} event$={videoEvent$} detail={detail} highlightList={highlightList} />
        </div>
      </div>
    </>
  );
};

export default PlayHistory;
