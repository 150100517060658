import ChartCore from '@/components/ChartCore';
import { DyClueAnalysisVO } from '@/services/live_stream/dy/type';
import { Validator } from '@/utils/validator';
import { useCreation } from 'ahooks';
import { get } from 'lodash';

const Chart = ({
  dyClueAnalysisData,
  type,
  name,
}: {
  dyClueAnalysisData?: DyClueAnalysisVO;
  type: 'traffic';
  name: string;
}) => {
  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {},
      series: [
        {
          name,
          type: 'pie',
          radius: ['40%', '60%'],
          label: {
            show: true,
            formatter: '{b}\n{d}%',
          },
          data: Object.entries(dyClueAnalysisData?.[type] || {}).map(([name, value]) => ({ value, name })),
        },
      ],
    }),
    [dyClueAnalysisData],
  );

  return (
    <ChartCore
      option={options}
      notMerge
      empty={Validator.isNilEmpty(get(dyClueAnalysisData, type))}
      style={{ height: 300 }}
    />
  );
};

export default Chart;
