import { getAccountInfo } from '@/services/account';
import { useRequest } from 'ahooks';
import { isEmpty, uniq } from 'lodash';

export const useAvaterMap = (openIds: (string | undefined)[]) => {
  const ids: string[] = (openIds || []).filter((v) => v) as unknown as any;
  return useRequest(
    async () => {
      if (isEmpty(ids)) return {};
      const list = await getAccountInfo(uniq(ids));
      return list.reduce((init, cur) => {
        init[cur['openId']] = cur;
        return init;
      }, {});
    },
    { refreshDeps: [ids.toString()], debounceWait: 100 },
  );
};
