import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { HomeUserAnalysisResult } from '@/services/home/type';
import { Format } from '@/utils/format';
import { Empty, Select, Table } from '@antd';
import { useCreation } from 'ahooks';
import { isEmpty } from 'lodash';
import { regionCascadMap } from './regionCascadeMap';
import { useEffect, useState } from 'react';
import { ProCard } from '@ant-design/pro-components';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

const ALL_KEY = '@_all';
export const ProvienceTable = ({ diminDatas }: { diminDatas?: HomeUserAnalysisResult[] }) => {
  const { tableProps: provinceTableProps } = useTableRequest(
    async () => {
      const datas = diminDatas?.filter((v) => v.audienceCharacterType == 'province_list');
      const { metrics: total } = datas?.find((v) => v.dimensions == ALL_KEY) || {};
      return {
        list: (datas || [])
          .filter((v) => v.dimensions != ALL_KEY)
          .sort((v1, v2) => v2.metrics - v1.metrics)
          .map((item, index) => ({
            id: index + 1,
            name: item.dimensions,
            value: item.metrics,
            rate: total ? Format.toPercent(Format.toRoundNumber(item.metrics / total, 4)) : '-',
          })),
      };
    },
    { refreshDeps: [diminDatas] },
  );

  return (
    <ProCard title={dict('PROVINCE_DISTRIBUTION')} {...headerFormCardProps}>
      <Table
        {...provinceTableProps}
        pagination={false}
        size="small"
        columns={[
          getTableColumn({
            title: dict('RANKINGS'),
            dataIndex: 'id',
          }),
          getTableColumn({
            title: dict('PROVINCE'),
            dataIndex: 'name',
          }),
          getTableColumn({
            title: dict('PEOPLE_COUNT'),
            dataIndex: 'value',
          }),
          getTableColumn({
            title: dict('PERCENTAGE'),
            dataIndex: 'rate',
          }),
        ]}
      />
    </ProCard>
  );
};

const specialCities = ['北京', '重庆', '上海', '天津', '香港特别行政区', '澳门特别行政区'];
export const CityTable = ({ diminDatas }: { diminDatas?: HomeUserAnalysisResult[] }) => {
  const provineceOptions = useCreation(() => {
    const provineceOptions = diminDatas
      ?.filter((v) => v.audienceCharacterType == 'province_list')
      ?.filter((v) => !specialCities.includes(v.dimensions))
      .filter((v) => v.dimensions != ALL_KEY)
      .map(({ dimensions }) => ({ label: dimensions, value: dimensions }));
    return provineceOptions;
  }, [diminDatas]);

  const [selectedProvince, selectProvinece] = useState<string>();

  const total = useCreation(
    () =>
      diminDatas?.find((v) => v.audienceCharacterType == 'province_list' && v.dimensions == selectedProvince)?.metrics,
    [diminDatas, selectedProvince],
  );

  useEffect(() => selectProvinece(provineceOptions?.[0]?.value), [provineceOptions]);

  const { tableProps: cityTableProps } = useTableRequest(
    async () => {
      if (!selectedProvince) return { list: [] };
      const cities = regionCascadMap[selectedProvince] || [];
      const datas = diminDatas?.filter((v) => v.audienceCharacterType == 'city_list' && cities.includes(v.dimensions));
      return {
        list: (datas || [])
          .filter((v) => v.dimensions != ALL_KEY)
          .sort((v1, v2) => v2.metrics - v1.metrics)
          .map((item, index) => ({
            id: index + 1,
            name: item.dimensions,
            value: item.metrics,
            rate: total ? Format.toPercent(Format.toRoundNumber(item.metrics / total, 4)) : '-',
          })),
      };
    },
    { refreshDeps: [diminDatas, selectedProvince, total] },
  );

  return (
    <ProCard
      title={dict('CITY_DISTRIBUTION')}
      {...headerFormCardProps}
      extra={
        <Select
          placeholder={dict('PLEASE_SELECT_SINGLE_NAME', { name: dict('PROVINCE') })}
          size="small"
          value={selectedProvince}
          options={provineceOptions}
          allowClear={false}
          onChange={(v) => selectProvinece(v as string)}
        />
      }
    >
      {isEmpty(provineceOptions) ? (
        <Empty />
      ) : (
        <Table
          {...cityTableProps}
          size="small"
          pagination={false}
          columns={[
            getTableColumn({
              title: dict('RANKINGS'),
              dataIndex: 'id',
            }),
            getTableColumn({
              title: dict('PROVINCE'),
              dataIndex: 'name',
            }),
            getTableColumn({
              title: dict('PEOPLE_COUNT'),
              dataIndex: 'value',
            }),
            getTableColumn({
              title: dict('PERCENTAGE'),
              dataIndex: 'rate',
            }),
          ]}
        />
      )}
    </ProCard>
  );
};
