import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { dict } from '@/hooks/useChangeLocale';
import { getLiveStreamPageData } from '@/services/live_stream/all';
import { Format } from '@/utils/format';
import { useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import React from 'react';

type DataStatisticCardProps = {
  searchPrams: any;
};

export const StatisticCardSimple: React.FC<DataStatisticCardProps> = ({ searchPrams }) => {
  const { data: item, loading } = useRequest(
    () => {
      const { accountId, ...rest } = searchPrams;
      const params = omit(rest, ['liveTimeStart', 'liveTimeStart']) as any;
      return getLiveStreamPageData({
        dateScreen: 0, //累积至今
        accountIds: accountId ? [accountId] : undefined,
        ...params,
      });
    },
    {
      refreshDeps: [searchPrams],
    },
  );

  const items: StatisticItem[] = [
    {
      title: dict('LIVE_TOTAL'),
      value: Format.toAmountNumber(item?.sessionNum),
      suffix: dict('STATIST_UNIT_CHANG'),
    },
    {
      title: dict('LIVE_DURATION'),
      value: Format.toAmountNumber(item?.liveDuration),
      mValues: [
        { value: Math.floor(get(item, 'liveDuration', 0) / 60 / 60), suffix: dict('STATIST_UNIT_HOUR') },
        { value: Math.floor((get(item, 'liveDuration', 0) / 60) % 60), suffix: dict('STATIST_UNIT_MIN') },
      ],
    },
    {
      title: dict('LIVE_INDICATOR_WATCH_UV'),
      value: Format.toAmountNumber(item?.viewNum),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: dict('LIVE_INDICATOR_COMMENT_COUNT'),
      value: Format.toAmountNumber(item?.commentNum),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: dict('LP_SCREEN_LIVE_SHARE_COUNT'),
      value: Format.toAmountNumber(item?.shareNum),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: dict('LP_SCREEN_LIVE_LIKE_COUNT'),
      value: Format.toAmountNumber(item?.likeNum),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('LIVE_STATIST_CLUE_NUM'),
      value: Format.toAmountNumber(item?.clueNum),
      suffix: dict('STATIST_UNIT_GE'),
    },
  ];

  return <StatisticCardPanel loading={loading} items={items} columns={items.length} />;
};
