import { IndicatorTitle } from '@/components/StatisticCard/IndicatorTitle';
import { dict } from '@/hooks/useChangeLocale';

export const indicatorMap = {
  lpScreenLiveWatchUv: {
    title: dict('LIVE_INDICATOR_UV'),
    tip: dict('LIVE_INDICATOR_UV_TIP'),
  },
  lpScreenLiveMaxWatchUvByMinute: {
    title: dict('LIVE_INDICATOR_MAX_WATCH_UV'),
    tip: dict('LIVE_INDICATOR_MAX_WATCH_UV_TIP'),
  },
  lpScreenLiveAvgWatchDuration: {
    title: dict('AVG_PLAY_DURATION'),
    tip: dict('LIVE_INDICATOR_AVG_WATCH_DURATION_TIP'),
  },
  lpScreenLiveFollowUv: {
    title: dict('LIVE_INDICATOR_FOLLOW_UV'),
    tip: dict('LIVE_INDICATOR_FOLLOW_UV_TIP'),
  },
  lpScreenLiveCommentUv: {
    title: dict('LIVE_INDICATOR_COMMENT_UV'),
    tip: dict('LIVE_INDICATOR_COMMENT_UV_TIP'),
  },
  lpScreenMsgConversationCount: {
    title: dict('LIVE_INDICATOR_MSG_CONVERSATION_COUNT'),
    tip: dict('LIVE_INDICATOR_MSG_CONVERSATION_COUNT_TIP'),
  },
  lpScreenClueUv: {
    title: dict('LIVE_INDICATOR_CLUE_UV'),
    tip: dict('LIVE_INDICATOR_CLUE_UV_TIP'),
  },
  lpScreenLiveWatchGtMinCount: {
    title: dict('LIVE_INDICATOR_WATCH_GT_MIN_COUNT'),
    tip: dict('LIVE_INDICATOR_WATCH_GT_MIN_COUNT_TIP'),
  },
  lpScreenLiveUserRealtime: {
    title: dict('LIVE_INDICATOR_USER_REALTIME'),
    tip: dict('LIVE_INDICATOR_USER_REALTIME_TIP'),
  },
  lpScreenLiveClueBusinessCardClickCount: {
    title: dict('LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT'),
    tip: dict('LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT_TIP'),
  },
  lpScreenLiveShowCount: {
    title: dict('LIVE_INDICATOR_SHOW_COUNT'),
    tip: dict('LIVE_INDICATOR_SHOW_COUNT_TIP'),
  },
  lpScreenLiveEnterRatio: {
    title: dict('LIVE_INDICATOR_ENTER_RATIO'),
    tip: dict('LIVE_INDICATOR_ENTER_RATIO_TIP'),
  },
  lpScreenLiveIconClickCount: {
    title: dict('LIVE_INDICATOR_ICON_CLICK_COUNT'),
    tip: dict('LIVE_INDICATOR_ICON_CLICK_COUNT_TIP'),
  },
  lpScreenLiveIconClickRate: {
    title: dict('LIVE_INDICATOR_ICON_CLICK_RATE'),
    tip: dict('LIVE_INDICATOR_ICON_CLICK_RATE_TIP'),
  },
  lpScreenLiveInteractionRatio: {
    title: dict('LIVE_INDICATOR_INTERACTION_RATIO'),
    tip: dict('LIVE_INDICATOR_INTERACTION_RATIO_TIP'),
  },
  lpScreenAdBizWechatCost: {
    title: dict('LIVE_INDICATOR_BIZ_WECHAT_COST'),
    tip: dict('LIVE_INDICATOR_BIZ_WECHAT_COST_TIP'),
  },
  lpScreenAdFormCount: {
    title: dict('LIVE_INDICATOR_FORM_COUNT'),
    tip: dict('LIVE_INDICATOR_FORM_COUNT_TIP'),
  },
  lpScreenLiveWatchCount: {
    title: dict('LIVE_INDICATOR_WATCH_COUNT'),
    tip: dict('LIVE_INDICATOR_WATCH_COUNT_TIP'),
  },
  lpScreenLiveFansWatchRatio: {
    title: dict('LIVE_INDICATOR_FANS_WATCH_RATIO'),
    tip: dict('LIVE_INDICATOR_FANS_WATCH_RATIO_TIP'),
  },
  lpScreenLiveAvgOnlineUvByRoom: {
    title: dict('LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM'),
    tip: dict('LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM_TIP'),
  },
  lpScreenUvWithPreview: {
    title: dict('LIVE_INDICATOR_UV_WITH_PREVIEW'),
    tip: dict('LIVE_INDICATOR_UV_WITH_PREVIEW_TIP'),
  },
  lpScreenLiveShareCount: {
    title: dict('LIVE_INDICATOR_SHARE_COUNT'),
    tip: dict('LIVE_INDICATOR_SHARE_COUNT_TIP'),
  },
  lpScreenLiveShareUv: {
    title: dict('LIVE_INDICATOR_SHARE_UV'),
    tip: dict('LIVE_INDICATOR_SHARE_UV_TIP'),
  },
  lpScreenLiveShareRatio: {
    title: dict('LIVE_INDICATOR_SHARE_RATIO'),
    tip: dict('LIVE_INDICATOR_SHARE_RATIO_TIP'),
  },
  lpScreenLiveLikeUv: {
    title: dict('LIVE_INDICATOR_LIKE_UV'),
    tip: dict('LIVE_INDICATOR_LIKE_UV_TIP'),
  },
  lpScreenLiveLikeCount: {
    title: dict('LIVE_INDICATOR_LIKE_COUNT'),
    tip: dict('LIVE_INDICATOR_LIKE_COUNT_TIP'),
  },
  lpScreenLiveLikeRatio: {
    title: dict('LIVE_INDICATOR_LIKE_RATIO'),
    tip: dict('LIVE_INDICATOR_LIKE_RATIO_TIP'),
  },
  lpScreenLiveCommentCount: {
    title: dict('LIVE_INDICATOR_COMMENT_COUNT'),
    tip: dict('LIVE_INDICATOR_COMMENT_COUNT_TIP'),
  },
  lpScreenLiveCommentRatio: {
    title: dict('LIVE_INDICATOR_COMMENT_RATIO'),
    tip: dict('LIVE_INDICATOR_COMMENT_RATIO_TIP'),
  },
  lpScreenLiveInteractionCount: {
    title: dict('LIVE_INDICATOR_INTERACTION_COUNT'),
    tip: dict('LIVE_INDICATOR_INTERACTION_COUNT_TIP'),
  },
  lpScreenLiveInteractionUvCount: {
    title: dict('LIVE_INDICATOR_INTERACTION_UV_COUNT'),
    tip: dict('LIVE_INDICATOR_INTERACTION_UV_COUNT_TIP'),
  },
  lpScreenLiveFansClubJoinUv: {
    title: dict('LIVE_INDICATOR_FANS_CLUB_JOIN_UV'),
    tip: dict('LIVE_INDICATOR_FANS_CLUB_JOIN_UV_TIP'),
  },
  lpScreenLiveFansClubJoinUvRatio: {
    title: dict('LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO'),
    tip: dict('LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO_TIP'),
  },
  lpScreenLiveFollowRatio: {
    title: dict('LIVE_INDICATOR_FOLLOW_RATIO'),
    tip: dict('LIVE_INDICATOR_FOLLOW_RATIO_TIP'),
  },
  lpScreenLiveClueConvertRatio: {
    title: dict('LIVE_INDICATOR_CLUE_CONVERT_RATIO'),
    tip: dict('LIVE_INDICATOR_CLUE_CONVERT_RATIO_TIP'),
  },
  lpScreenAdFormCost: {
    title: dict('LIVE_INDICATOR_FORM_COST'),
    tip: dict('LIVE_INDICATOR_FORM_COST_TIP'),
  },
  lpScreenLongtermMsgClueUv: {
    title: dict('LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV'),
    tip: dict('LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV_TIP'),
  },
  lpScreenLiveClueBusinessCardClickRate: {
    title: dict('LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE'),
    tip: dict('LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE_TIP'),
  },
  lpScreenAdBizWechatAddCount: {
    title: dict('LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT'),
    tip: dict('LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT_TIP'),
  },
  lpScreenClueCost: {
    title: dict('LIVE_INDICATOR_CLUE_COST'),
    tip: dict('LIVE_INDICATOR_CLUE_COST_TIP'),
  },
  lpScreenLiveStatCost: {
    title: dict('LIVE_INDICATOR_STAT_COST'),
    tip: dict('LIVE_INDICATOR_STAT_COST_TIP'),
  },
};

type IndicatorTitleProps = {
  type?: keyof typeof indicatorMap;
  title?: string;
  tip?: string;
};
export const LiveIndicatorTitle: React.FC<IndicatorTitleProps> = ({ type, ...rest }) => {
  const { title = '', tip = '' } = type ? indicatorMap[type] : rest;
  return <IndicatorTitle title={title} tip={tip} />;
};
