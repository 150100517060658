import { EmojiImgText } from '@/components/EmojiImg';
import { MEDIA_ID_MAPPING, MEDIA_KEY_ENUM, ROUTE_PATH, getMediaName } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { getKeywords } from '@/pages/system/keyword/const.var';
import { QueryKeywordCategoryItem } from '@/services/keyword_category/type';
import { downloadComment, getCommentPage } from '@/services/live_comment';
import { DateUtils } from '@/utils/dateUtil';
import { Alert, Button, Space, Spin, Table, TableColumnType, Typography } from '@antd';
import { useRequest } from 'ahooks';
import { get, isArray, isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'umi';

type ListPros = {
  params: any;
  category?: QueryKeywordCategoryItem | QueryKeywordCategoryItem[];
  queryUnclassified?: boolean;
};
const { Link } = Typography;

const List = ({ params, category, queryUnclassified = false }: ListPros) => {
  const navigate = useNavigate();

  const { user } = useAppContext();
  const [downloadParams, setDownloadParams] = useState<any>();
  const [keyword, setKeyword] = useState('');

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const { content, title, categoryList } = filters;
      const keyword = get(content, '[0]');
      const queryAll = isArray(category) && isEmpty(categoryList);
      const categoryIds = isArray(category)
        ? (queryAll ? category.map((v) => v.id) : categoryList).join(',')
        : get(category, 'id');

      const sortParams: any = {};
      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = sorter.field;
        sortParams.direction = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
      }

      const downloadParams = {
        ...sortParams,
        ...params,
        queryUnclassified,
        queryAll: queryAll && !queryUnclassified,
        searchWord: keyword,
        // nickName: get(nickName, '[0]'),
        title: get(title, '[0]'),
        bid: user?.bid,
        categoryIds,
      };
      setDownloadParams(downloadParams);
      setKeyword(keyword);
      return getCommentPage({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, category, user, queryUnclassified], debounceWait: 200 },
  );

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadComment(downloadParams), {
    manual: true,
  });

  const { getBtnPermissionConfig } = usePermission();

  return (
    <>
      <Alert
        message={dict('LIVE_DETAIL_PLAY_TIP')}
        style={{ marginTop: -10, border: 0, backgroundColor: 'transparent' }}
        showIcon
        closable={false}
        action={
          <Spin spinning={downloadLoading}>
            <Link {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)} onClick={download}>
              {dict('ACTION_DOWNLOAD_DATA')}
            </Link>
          </Spin>
        }
      />
      <Table
        {...tableProps}
        columns={
          [
            getTableColumn({
              title: dict('COMMENT_TIME'),
              align: 'center',
              dataIndex: 'create_time',
              sorter: true,
              render: (_, r) => DateUtils.formatDateTime(r.createTime),
            }),
            getTableColumn({
              title: dict('NICKNAME'),
              dataIndex: 'nickName',
              // ...getColumnSearchProps({
              //   dataIndex: 'nickName',
              // }),
              // render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.nickName} />,
            }),
            getTableColumn({
              title: dict('BULLET_CONTENT'),
              dataIndex: 'content',
              align: 'left',
              ...getColumnSearchProps({
                dataIndex: 'content',
              }),
              ellipsis: true,
              render: (v, record) => (
                <EmojiImgText content={v} searchWord={keyword} keywords={getKeywords(record.keywordMap)} />
              ),
            }),

            isArray(category) && !queryUnclassified
              ? getTableColumn({
                  title: dict('KEYWORD_CLASSIFICATION'),
                  dataIndex: 'categoryList',
                  ellipsis: true,
                  filters: category.map(({ id, name }: any) => ({ text: name, value: id })),
                  render: (categoryNames) => categoryNames?.join(',') || '-',
                })
              : null,
            queryUnclassified
              ? null
              : getTableColumn({
                  title: dict('KEYWORD_HIT'),
                  dataIndex: 'keywordList',
                  ellipsis: true,
                  render: (keywords) => keywords?.join(',') || '-',
                }),
            getTableColumn({
              title: dict('PLATFORM'),
              dataIndex: 'mediaId',
              render: (v) => getMediaName(v),
            }),
            getTableColumn({
              title: dict('LIVE_ROOM_ID'),
              dataIndex: 'roomId',
              width: 80,
            }),

            getTableColumn({
              title: dict('PUBLISH_ACCOUNT'),
              dataIndex: 'accountName',
            }),

            getTableColumn({
              title: dict('ORGANIZATION_TYPE'),
              dataIndex: 'orgFullName',
            }),
            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 150,
              render: (_, record: any) => {
                const platform = MEDIA_ID_MAPPING[record.mediaId];
                const videoPlayDisabled = record.mediaId != MEDIA_KEY_ENUM.DOUYIN;
                const commentListDisabled = record.mediaId == MEDIA_KEY_ENUM.KUAISHOU;

                return (
                  <Space>
                    <Button
                      type="link"
                      size="small"
                      title={dict('ACTION_LIVE_REPLAY')}
                      disabled={videoPlayDisabled}
                      {...getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW)}
                      onClick={() =>
                        navigate(
                          `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}/${platform}/${ROUTE_PATH.DETAIL}/${record.roomId}?type=playhistory`,
                        )
                      }
                    >
                      {dict('ACTION_LIVE_REPLAY')}
                    </Button>

                    <Button
                      size="small"
                      title={dict('ACTION_VIEW_COMMENT')}
                      type="link"
                      disabled={commentListDisabled}
                      {...getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW)}
                      onClick={() =>
                        navigate(
                          `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}/${platform}/${ROUTE_PATH.DETAIL}/${record.roomId}?type=barrage`,
                        )
                      }
                    >
                      {dict('ACTION_MORE_BARRAGE')}
                    </Button>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableColumnType<any>[]
        }
      />
    </>
  );
};

export default List;
