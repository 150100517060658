import ChartCore from '@/components/ChartCore';
import { HighlightTime } from '@/services/live_stream/all/type';
import { DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Card, Select, Typography } from '@antd';
import { useBoolean, useCreation, useMemoizedFn, useRequest, useSafeState } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import dayjs from 'dayjs';
import { EChartsInstance } from 'echarts-for-react';
import { get, max, uniqBy } from 'lodash';
import { useEffect, useState } from 'react';
import { VideoEventType } from '.';
import { WxLiveStreamData } from '@/services/live_stream/wx/type';
import { getWxLiveStreamDetailTrend } from '@/services/live_stream/wx';
import { TIME_TYPE_ENUM } from '../../../detail/const.var';
import { dict } from '@/hooks/useChangeLocale';

const { Text } = Typography;

const padding = 80;
const HighLightMoment = dict('LIVE_DETAIL_TAB_HIGH_LIGHT');
const getOption = (data, labels, indicator, indicatorOptions, highlightList) => {
  let maxData = 0;

  const options = {
    tooltip: {
      trigger: 'axis',
      // axisPointer: {
      //   type: 'shadow',
      // },
      formatter: function (params) {
        if (!params.length) return;
        let str = `${params[0].axisValue}<br/>`;
        str += uniqBy<any>(params, 'seriesName')
          .map((item, idx) => {
            const records = params.filter((record) => record.seriesName == item.seriesName);
            const value =
              item.seriesName == HighLightMoment
                ? records.map((record) => record.data?.record?.reason).join('、')
                : records.map((record) => record.data).join(' / ');
            return `${item.marker}${item.seriesName}:&nbsp; <span style="float:right;font-weight:bold;">${value}</span>`;
          })
          .join('<br/>');
        return str;
      },
    },
    grid: [
      {
        top: 20,
        left: padding,
        right: padding,
        bottom: 60,
        containLabel: false,
      },
      {
        left: padding,
        right: padding,
        bottom: 20,
        height: '1',
        containLabel: false,
      },
    ],
    dataZoom: {
      type: 'inside',
      xAxisIndex: [0, 1],
    },
    xAxis: [
      {
        type: 'category',
        // offset: 10,
        data: labels,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: function (value) {
            return `${DateUtils.format(value, 'HH:mm')}`;
          },
          margin: 10,
        },
      },
      {
        gridIndex: 1,
        type: 'category',
        data: labels,
        axisLine: {
          lineStyle: {
            color: '#F4F6FB',
            width: 15,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
      },
    ],

    series: [
      ...indicatorOptions
        .filter((v) => indicator.includes(v.value))
        .map(({ label, value }) => {
          const indicatorData = data.map((v) => v[value]);
          maxData = max([maxData, ...indicatorData]);

          return {
            name: label,
            smooth: true,
            xAxisIndex: 0,
            yAxisIndex: 0,
            areaStyle: {
              opacity: 0,
            },
            type: 'line',
            data: indicatorData,
          };
        }),
      {
        name: HighLightMoment,
        type: 'scatter',
        xAxisIndex: 1,
        yAxisIndex: 1,
        data: [
          ...(highlightList || []).map((v) => ({
            value: [DateUtils.format(v.startTime, DATE_MINI_FORMAT_STR), 0],
            record: v,
          })),
        ],
        itemStyle: {
          color: '#C0D2F8',
          borderColor: '#709EF8',
        },
        symbol: 'circle',
        symbolSize: 8,
      },
    ],

    axisPointer: {
      snap: false,
      link: {
        xAxisIndex: 'all',
      },
    },
  };

  return {
    ...options,
    yAxis: [
      {
        type: maxData >= 10000 ? 'log' : 'value',
      },
      {
        name: HighLightMoment,
        type: 'value',
        gridIndex: 1,
        show: false,
      },
    ],
  };
};

const tip = dict('LIVE_INDICATOR_TIP');
const defaultIndecator = [
  'lpScreenLiveMaxWatchUvByMinute',
  'lpScreenLiveWatchUvByMinute',
  'lpScreenLiveLeaveUvByMinute',
];

//直播趋势分为流量趋势（指标有实时在线人数、进房人数和离开人数）、互动趋势（指标有评论次数、点赞次数和分享直播间次数）。
const indicatorOptions = [
  {
    label: dict('TRAFFIC_TREND'),
    options: [
      { label: dict('LIVE_INDICATOR_USER_REALTIME'), value: 'lpScreenLiveMaxWatchUvByMinute' },
      { label: dict('ENTER_ROOM_USER_COUNT'), value: 'lpScreenLiveWatchUvByMinute' },
      { label: dict('LEAVE_ROOM_USER_COUNT'), value: 'lpScreenLiveLeaveUvByMinute' },
    ],
  },
  {
    label: dict('INTERACTIVE_TREND'),
    options: [
      { label: dict('LIVE_INDICATOR_COMMENT_COUNT'), value: 'lpScreenLiveCommentCount' },
      { label: dict('LP_SCREEN_LIVE_LIKE_COUNT'), value: 'lpScreenLiveLikeCount' },
      { label: dict('SHARE_LIVE_ROOM_TIME_COUNT'), value: 'sharingPv' },
    ],
  },
];

type LineChartProps = {
  roomId: string;
  event$: EventEmitter<VideoEventType>;
  detail?: WxLiveStreamData;
  highlightList?: HighlightTime[];
};

const LineChart: React.FC<LineChartProps> = ({ roomId, event$, detail, highlightList }) => {
  const [chartInstance, setChartInstance] = useSafeState<EChartsInstance>();
  const [playTime, setPlayTime] = useSafeState<number>();
  const [indicator, setIndicator] = useState(defaultIndecator);
  const [hovering, { setTrue: setHovering, setFalse: cancelHovering }] = useBoolean(false);

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getWxLiveStreamDetailTrend({ roomId, timeType: TIME_TYPE_ENUM.WHOLE_LIVE });

      return list.reduce((obj, cur: any) => {
        obj[DateUtils.format(cur['dimensions'], DATE_MINI_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [roomId] },
  );

  event$.useSubscription(({ action, value }) => {
    if (action == 'updatePlayTime') setPlayTime(value);
  });

  //视频播放进度
  const options = useCreation(() => {
    const dataMap = Object.entries(data);
    const xAxisLabels = Object.keys(data);

    const option = getOption(
      dataMap.map((v) => v[1]),
      xAxisLabels,
      indicator,
      indicatorOptions.reduce((arr, cur: any) => arr.concat(cur.options), []),
      highlightList,
    );

    return option;
  }, [data, indicator, highlightList, chartInstance]);

  useEffect(() => {
    if (hovering) return;
    chartInstance?.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: Math.floor((playTime ?? 0) / 60),
    });
  }, [playTime, chartInstance, hovering]);

  const onChartClick = useMemoizedFn((params) => {
    if (params.seriesName == HighLightMoment) {
      const playTime = dayjs(params.value[0]).diff(dayjs(detail?.liveStartTime || '-'), 'second');
      event$.emit({ action: 'updateVideoPlayTime', value: Math.floor(playTime) });
    }
  });

  return (
    <>
      <Card
        title={dict('LIVE_TREND')}
        loading={loading}
        headStyle={{ background: '#f8f9fa', borderTop: '1px solid #e9ecf0', minHeight: 35 }}
        bordered={false}
        extra={
          <Select
            value={indicator}
            title={tip}
            mode="multiple"
            maxTagCount="responsive"
            options={indicatorOptions}
            onChange={(value) => {
              const valCount = get(value, 'length', 0);
              setIndicator(valCount < 1 ? defaultIndecator : (value as string[]).splice(-3));
            }}
            size="small"
            showSearch={false}
            style={{ width: 320 }}
          />
        }
      >
        <div onMouseOver={setHovering} onMouseOut={cancelHovering}>
          <ChartCore
            option={options}
            notMerge
            style={{ height: 600 }}
            onChartReady={(instance) => {
              setTimeout(() => {
                instance.resize();
              });
              setChartInstance(instance);
              instance.on('click', onChartClick);
            }}
          />
          <div style={{ position: 'absolute', bottom: 10, left: 15 }}>
            <Text>{HighLightMoment}</Text>
          </div>
        </div>
      </Card>
    </>
  );
};

export default LineChart;
