import GoBackBar from '@/components/GoBackBar';
import Container, { Blank, FormCard } from '@/components/PageCard';
import { ROUTE_PATH, defaultFormItemConfig, MEDIA_ENUM, getMediaName } from '@/constants/const';
import { DateUtils } from '@/utils/dateUtil';
import { Card, Col, Descriptions, Empty, Form, Row, Space, Timeline, Typography } from '@antd';
import { useMemoizedFn, useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import React, { ReactNode, useState } from 'react';

import { history, useNavigate, useParams, useSearchParams } from 'umi';

import style from './layout.less';
import { FormInstance } from 'antd/lib/form';
import EnumText from '@/components/Form/Field/EnumName';
import { dict } from '@/hooks/useChangeLocale';

export type ClueDetailLayout<C, R> = {
  name: (clueDetail: C) => ReactNode;
  fetchClueDetail: (id: string) => Promise<C | any>;
  userInfo: (params: { clueDetail: C }) => ReactNode;
  clueInfo: (params: { clueDetail: C }) => ReactNode;
  fetchRecordList: (params: any, clueDetail: C) => Promise<R[] | any[]>;
  recordSearchForm: (params: { instance: FormInstance }) => ReactNode;
  recordSearchFormDefaultParams: Record<string, any>;
  recordTimeLineKey?: string; // 时间
  media: MEDIA_ENUM;
};

export const DescriptionsItem = ({ label, children }: { label: ReactNode; children: ReactNode }) => {
  return <Descriptions.Item label={label}>{children || '-'}</Descriptions.Item>;
};

export default <C, R>({
  name,
  fetchClueDetail,
  userInfo,
  clueInfo,
  fetchRecordList,
  recordSearchForm,
  recordSearchFormDefaultParams,
  recordTimeLineKey = 'createTime',
  media,
}: ClueDetailLayout<C, R>) => {
  const [form] = Form.useForm();
  const [searchForm] = Form.useForm();

  const [params, setParams] = useState(recordSearchFormDefaultParams);
  const { id } = useParams();
  const [queryParams] = useSearchParams();
  const cluesId = queryParams.get('clueId');

  const navigateTo = useNavigate();

  const { data: clueDetail, loading } = useRequest(
    async () => {
      if (!id) return undefined;
      const detail = await fetchClueDetail(id);
      form?.setFieldsValue(detail);
      return detail;
    },
    { refreshDeps: [id, fetchClueDetail] },
  );

  const { data: list = [], loading: inteactiveLoading } = useRequest(
    async () => {
      if (!clueDetail) return [];
      return fetchRecordList({ ...params, cluesId }, clueDetail);
    },
    { refreshDeps: [params, clueDetail, fetchRecordList, id], debounceWait: 200 },
  );

  const getClueInteactiveData = useMemoizedFn((data) => {
    const { type, phone, name, nickName, title, content, ctTime, accountId, accountName, actionType } = data;
    const maxWidth = 300;
    const paramstr = `?startTime=${ctTime}&accountId=${accountId}&nickName=${nickName}`;
    const mediaName = getMediaName(media);
    if (type == 'COMMENT') {
      return (
        <>
          <div style={{ maxWidth }}>
            {dict('CLUE_DETAIL_COMMENT_TPL', {
              nickName,
              mediaName,
              accountName,
              title,
              content,
            })}
          </div>
          <a onClick={() => navigateTo(`/${ROUTE_PATH.INTERACTIVE}/${ROUTE_PATH.COMMENT}${paramstr}`)}>
            {dict('VIEW_DETAIL')}
          </a>
        </>
      );
    } else if (type == 'LIVE_COMMENT') {
      return (
        <>
          <div style={{ maxWidth }}>
            {dict('CLUE_DETAIL_BULLET_TPL', {
              nickName,
              accountName,
              mediaName,
              content,
            })}
          </div>
          <a onClick={() => navigateTo(`/${ROUTE_PATH.INTERACTIVE}/${ROUTE_PATH.BULLET}${paramstr}`)}>
            {dict('VIEW_DETAIL')}
          </a>
        </>
      );
    } else if (type == 'CONVERSATION_HISTORY') {
      return (
        <>
          <div style={{ maxWidth }}>
            {dict('CLUE_DETAIL_SESSION_TPL', {
              nickName,
              mediaName,
              accountName,
              content,
            })}
          </div>
          <a
            onClick={() =>
              navigateTo(`/${ROUTE_PATH.INTERACTIVE}/${ROUTE_PATH.CONVERSATION}/${ROUTE_PATH.HISTORY}${paramstr}`)
            }
          >
            {dict('VIEW_DETAIL')}
          </a>
        </>
      );
    }
    // 抖音使用nickName、content，其他使用name、phone
    return (
      <div style={{ maxWidth }}>
        {dict('CLUE_DETAIL_CLUE_TPL', {
          nickName: name || nickName,
          mediaName,
          accountName,
          content: phone || content,
          actionType: actionType ? <EnumText type="ClueActionTypeEnum" value={actionType} /> : dict('PRIVATE_LETTER'),
        })}
      </div>
    );
  });

  return (
    <Container
      loading={loading}
      pageHeaderRender={() => <GoBackBar name={dict('VIEW_CLUE_DETAIL')} back={() => history.back()} />}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <FormCard>
        <Descriptions title={name(clueDetail) || '-'} column={4}>
          {userInfo({ clueDetail })}
        </Descriptions>
      </FormCard>
      <Blank />

      <Row gutter={12}>
        <Col span={18}>
          <FormCard title={dict('INTERACTION_RECORDS')}>
            <Form
              layout="inline"
              form={searchForm}
              initialValues={recordSearchFormDefaultParams}
              onFieldsChange={(_, allFields) => {
                const params: any = allFields.reduce((p, cur) => {
                  const fieldname = cur.name.toString();
                  p[fieldname] = cur.value;
                  return p;
                }, {});

                setParams(params);
              }}
            >
              {recordSearchForm({ instance: searchForm })}
            </Form>
          </FormCard>
          <Blank />
          <Card
            loading={inteactiveLoading || loading}
            bodyStyle={{ maxHeight: 'calc(100vh - 390px)', overflowY: 'auto' }}
          >
            {isEmpty(list) ? (
              <Empty />
            ) : (
              <Timeline
                items={list
                  .sort(
                    (v1, v2) =>
                      DateUtils.toTimestamp(v2[recordTimeLineKey]) - DateUtils.toTimestamp(v1[recordTimeLineKey]),
                  )
                  .map((v) => ({
                    dot: <div style={{ width: '10px', height: '10px', borderRadius: '50%', background: '#AAAAAA' }} />,
                    children: (
                      <Space direction="vertical" style={{ width: '100%' }}>
                        <Typography.Text>{DateUtils.formatDateTime(v[recordTimeLineKey])}</Typography.Text>
                        <div style={{ background: '#F2F2F2', padding: '5px 10px' }}>{getClueInteactiveData(v)}</div>
                      </Space>
                    ),
                  }))}
              />
            )}
          </Card>
        </Col>
        <Col span={6}>
          <Form
            {...defaultFormItemConfig}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            className={style.form}
            form={form}
          >
            {clueInfo({ clueDetail })}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
