import ChartCore from '@/components/ChartCore';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { LiveWatchSourceVO } from '@/services/live_stream/all/type';
import { Format } from '@/utils/format';
import { useCreation } from 'ahooks';

const Chart = ({ liveWatchSourceVo }: { liveWatchSourceVo?: LiveWatchSourceVO }) => {
  const { getEnum } = useAppContext();

  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {},
      series: [
        {
          name: dict('LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE'),
          type: 'pie',
          radius: ['40%', '60%'],
          label: {
            show: true,
            formatter: '{b}\n{d}%',
          },
          data: Object.entries(liveWatchSourceVo || {}).map(([name, value]) => ({
            value,
            name: Format.toArrLabel(getEnum('LiveWatchSourceTypeEnum'), name, 'name', 'displayName'),
          })),
        },
      ],
    }),
    [liveWatchSourceVo],
  );

  return <ChartCore option={options} notMerge empty={!liveWatchSourceVo} style={{ height: 300 }} />;
};

export default Chart;
