import { useRequest } from 'ahooks';
import { Spin } from '@antd';
import { getWxLiveStreamChannelSource } from '@/services/live_stream/wx';
import { WxLiveStreamDetail } from '@/services/live_stream/wx/type';
import { CHANNEL_COUNT_TYPE } from '@/pages/live/report/weixin/detail/const.var';
import CommonPieChart from '@/pages/live/report/weixin/components/detail/Data/watch-profile-chart/CommonPieChart';

const Chart = ({
  liveStreamData,
  type,
  roomId,
}: {
  liveStreamData?: WxLiveStreamDetail;
  type: CHANNEL_COUNT_TYPE;
  roomId: string;
}) => {
  const { data = {}, loading } = useRequest(
    async () => {
      const channelData = await getWxLiveStreamChannelSource({
        roomId,
        type,
      });
      const formatChannelData = JSON.parse(channelData || '[]');
      return formatChannelData.map((item) => ({
        name: item.typeVal,
        value: item.dataVal,
      }));
    },
    { refreshDeps: [roomId, type] },
  );

  return (
    <Spin spinning={loading}>
      <CommonPieChart liveStreamData={liveStreamData} data={data} />
    </Spin>
  );
};

export default Chart;
