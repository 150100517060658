import ChartCore from '@/components/ChartCore';
import { HomeUserAnalysisResult } from '@/services/home/type';
import { useCreation } from 'ahooks';
import { getConvertAgeDimensionsName, getPieChartOptions } from './chart.config';
import { isEmpty } from 'lodash';

const Chart = ({
  data,
  showLabelRate,
  valueEqRate,
}: {
  data?: HomeUserAnalysisResult[];
  showLabelRate?: boolean; //显示占比
  valueEqRate?: boolean; //value值是否和占比值相等
}) => {
  const list = useCreation(() => {
    return (
      data
        ?.filter((v) => ['age', 'age_list'].includes(v.audienceCharacterType))
        .filter((v) => v.dimensions != '@_all') //微信 汇总 数据 需要过滤
        // .filter((v) => v.metrics > 0)
        .map((v) => {
          return {
            name: getConvertAgeDimensionsName(v.dimensions),
            value: v.metrics,
          };
        })
        .sort((v1, v2) => (v1.name > v2.name ? 1 : -1))
    );
  }, [data]);

  return (
    <ChartCore
      empty={isEmpty(list)}
      option={getPieChartOptions(list || [], showLabelRate, valueEqRate)}
      notMerge
      style={{ height: 300 }}
    />
  );
};

export default Chart;
