import ChartCore from '@/components/ChartCore';
import { chortColors } from '@/components/ChartCore/theme';
import { dict } from '@/hooks/useChangeLocale';
import { HomeUserAnalysisResult } from '@/services/home/type';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { useCreation } from 'ahooks';
import { sum } from 'lodash';

const Chart = ({
  diminDatas,
  type,
  hideValue,
}: {
  diminDatas?: HomeUserAnalysisResult[];
  hideValue?: boolean;
  type: 'province' | 'crowd' | 'city';
}) => {
  const data = useCreation(() => {
    return diminDatas
      ?.filter((v) => v.audienceCharacterType == type)
      .sort((v1, v2) => v2.metrics - v1.metrics)
      .filter((_, index) => index < 10)
      .reduce((obj, v) => {
        obj[v.dimensions] = v.metrics;
        return obj;
      }, {});
  }, [diminDatas, type]);

  const total = sum(Object.values(data ?? {}));
  const options = useCreation(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: hideValue ? '{b}： {c}%' : `{b}： {c}${dict('PEOPLE')}`,
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: Object.keys(data ?? {}),
      },
      series: [
        {
          type: 'bar',
          realtimeSort: true,
          label: {
            show: true,
            formatter: function (params) {
              return hideValue ? `${params.value}%` : Format.toPercent(total ? params.value / total : 0);
            },
          },

          data: Object.values(data || {}).map((value: any, index) => ({
            value,
            itemStyle: {
              color: chortColors[index % chortColors.length],
            },
          })),
        },
      ],
    }),
    [data],
  );

  return <ChartCore option={options} notMerge empty={Validator.isNilEmpty(data)} style={{ height: 300 }} />;
};

export default Chart;
