import { StatisticCardPanel } from '@/components/StatisticCard';
import { getWxLiveStreamPageData } from '@/services/live_stream/wx';
import { Format } from '@/utils/format';
import { useRequest } from 'ahooks';
import { get, omit } from 'lodash';
import React from 'react';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { dict } from '@/hooks/useChangeLocale';

type DataStatisticCardProps = {
  searchPrams: any;
};

export const StatisticCardSimple: React.FC<DataStatisticCardProps> = ({ searchPrams }) => {
  const { data: item, loading } = useRequest(
    () => {
      const { accountId, ...rest } = searchPrams;
      const params = omit(rest, ['liveTimeStart', 'liveTimeStart']) as any;
      return getWxLiveStreamPageData({
        dateScreen: 0, //累积至今
        accountIds: accountId ? [accountId] : undefined,
        ...params,
      });
    },
    {
      refreshDeps: [searchPrams],
    },
  );

  return (
    <StatisticCardPanel
      loading={loading}
      items={[
        {
          title: (
            <LiveIndicatorTitle title={dict('LIVE_STATIST_LIVE_TOTAL_NUM')} tip={dict('LIVE_STATIST_LIVE_NUM_TIP')} />
          ),
          value: Format.toAmountNumber(item?.sessionNum),
          suffix: dict('STATIST_UNIT_CHANG'),
        },
        {
          title: (
            <LiveIndicatorTitle
              title={dict('LIVE_STATIST_LIVE_DURATION')}
              tip={dict('LIVE_STATIST_LIVE_DURATION_TIP')}
            />
          ),
          value: Format.toAmountNumber(item?.liveDuration),
          mValues: [
            { value: Math.floor(get(item, 'liveDuration', 0) / 60 / 60), suffix: dict('STATIST_UNIT_HOUR') },
            { value: Math.floor((get(item, 'liveDuration', 0) / 60) % 60), suffix: dict('STATIST_UNIT_MIN') },
            // { value: Format.toRoundNumber(get(item, 'liveDuration', 0) % 60), suffix: dict('STATIST_UNIT_SEC') },
          ],
        },
        {
          title: <LiveIndicatorTitle title={dict('AVG_WATCH_TIME')} tip={dict('LIVE_STATIST_DURATION_AVG_TIP')} />,
          value: Format.toAmountNumber(item?.liveDurationAvg),
          mValues: [
            { value: Math.round(get(item, 'liveDurationAvg', 0) / 60), suffix: dict('STATIST_UNIT_MIN') },
            // { value: Format.toRoundNumber(get(item, 'liveDurationAvg', 0) % 60), suffix: dict('STATIST_UNIT_SEC') },
          ],
        },

        {
          title: <LiveIndicatorTitle title={dict('LIVE_STATIST_VIEW_NUM')} tip={dict('LIVE_STATIST_VIEW_NUM_TIP')} />,
          value: Format.toAmountNumber(item?.viewNum),
          suffix: dict('STATIST_UNIT_REN'),
        },
        {
          title: <LiveIndicatorTitle title={dict('LIVE_STATIST_CLUE_NUM')} tip={dict('LIVE_STATIST_CLUE_NUM_TIP')} />,
          value: Format.toAmountNumber(item?.clueNum),
          suffix: dict('STATIST_UNIT_GE'),
        },
      ]}
    />
  );
};
