import { useEventEmitter } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import React, { useState } from 'react';
import LeftNav from './LeftNav';
import List from './List';

type BarrageTableListProps = {
  roomId: string;
};
export const BarrageTableList: React.FC<BarrageTableListProps> = ({ roomId }) => {
  const [filter, setFilter] = useState<any>({});

  const event$: EventEmitter<{ action: string; value: any }> = useEventEmitter();

  event$.useSubscription(({ action, value }) => {
    if (action == 'updateFilter') setFilter(value);
  });

  return (
    <div className="page-container" style={{ maxHeight: 680, border: '1px solid #ececec', overflow: 'hidden' }}>
      <LeftNav roomId={roomId} filter={filter} event$={event$} />
      <List roomId={roomId} filter={filter} event$={event$} />
    </div>
  );
};
