import { Blank, FormCard } from '@/components/PageCard';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { ProdStatisticCardSimple as DouyinStatisticCard } from '@/pages/matrix-operation/production/douyin/components/list/ProdStatisticCard';
import { ProdStatisticCardSimple as KuaishouStatisticCard } from '@/pages/matrix-operation/production/kuaishou/components/list/ProdStatisticCard';
import { ProdStatisticCardSimple as WxProdStatisticCard } from '@/pages/matrix-operation/production/weixin/components/list/ProdStatisticCard';
import { ProdStatisticCardSimple as XhsProdStatisticCard } from '@/pages/matrix-operation/production/xiaohongshu/components/list/ProdStatisticCard';

import DyVideoList from '@/pages/matrix-operation/production/douyin/components/list/List';
import KsVideoList from '@/pages/matrix-operation/production/kuaishou/components/list/List';
import WxVideoList from '@/pages/matrix-operation/production/weixin/components/list/List';
import XhsVideoList from '@/pages/matrix-operation/production/xiaohongshu/components/list/List';

import DyTrendChart from '@/pages/matrix-operation/production/douyin/components/detail/Data/LineChart';
import WxTrendChart from '@/pages/matrix-operation/production/weixin/components/detail/Data/LineChart';
import KsTrendChart from '@/pages/matrix-operation/production/kuaishou/components/detail/Data/LineChart';
import XhsTrendChart from '@/pages/matrix-operation/production/xiaohongshu/components/detail/Data/LineChart';

import { DateUtils } from '@/utils/dateUtil';
import { ProCard } from '@ant-design/pro-components';
import { useSafeState } from 'ahooks';
import dayjs from 'dayjs';
import { Key } from 'react';
import { Space } from '@antd';
import DateRangePicker from '@/components/TableFilter/RangePicker';
import { dict } from '@/hooks/useChangeLocale';

type ContentProps = {
  accountId?: Key;
  mediaId: MEDIA_KEY_ENUM;
};

//平台组件Mapping
const mediaComponentsMap = {
  [MEDIA_KEY_ENUM.DOUYIN]: {
    StatisticCard: DouyinStatisticCard,
    LineChart: DyTrendChart,
    List: DyVideoList,
  },
  [MEDIA_KEY_ENUM.KUAISHOU]: {
    StatisticCard: KuaishouStatisticCard,
    LineChart: KsTrendChart,
    List: KsVideoList,
  },
  [MEDIA_KEY_ENUM.WEIXIN]: {
    StatisticCard: WxProdStatisticCard,
    LineChart: WxTrendChart,
    List: WxVideoList,
  },
  [MEDIA_KEY_ENUM.XIAOHONGSHU]: {
    StatisticCard: XhsProdStatisticCard,
    LineChart: XhsTrendChart,
    List: XhsVideoList,
  },
};

const today = dayjs();
const before30Day = today.subtract(30, 'day');
const initSearchParams = {
  roomId: '',
  publish_time: DateUtils.convertRangeStr([before30Day, today], true),
};

export default ({ accountId, mediaId }: ContentProps) => {
  const { StatisticCard, LineChart, List } = mediaComponentsMap[mediaId];
  const [params, setParams] = useSafeState<any>(initSearchParams);

  return (
    <>
      <div style={{ textAlign: 'right' }}>
        <Space>
          {dict('PUBLISH_TIME')}
          <DateRangePicker
            value={params['publish_time'].map(dayjs)}
            showTime
            allowClear={false}
            onChange={(publish_time) =>
              setParams((prev) => ({ ...prev, publish_time: DateUtils.convertDateTimeRangeStr(publish_time || []) }))
            }
          />
        </Space>
      </div>
      <Blank />
      <FormCard title={dict('DATA_INDICATORS')} boxShadow={false}>
        <StatisticCard searchPrams={{ account_id: [accountId] }} />
      </FormCard>
      <Blank />
      <FormCard title={dict('TREND_ANALYSIS')} boxShadow={false}>
        <LineChart filter={{ id: '', accountIds: [accountId] }} />
      </FormCard>
      <Blank />
      <ProCard.Group boxShadow={false}>
        <List
          params={{
            account_id: [accountId],
            ...params,
          }}
        />
      </ProCard.Group>
    </>
  );
};
