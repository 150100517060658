import ChartCore from '@/components/ChartCore';
import { chortColors } from '@/components/ChartCore/theme';
import { HomeUserAnalysisResult } from '@/services/home/type';
import { useCreation } from 'ahooks';
import { isEmpty, round } from 'lodash';
import { getConvertAgeDimensionsName } from './chart.config';

const getConvertDimensionsName = (dimension: string, type) => {
  if (type != 'age') return dimension;
  return getConvertAgeDimensionsName(dimension);
};

export const RateBarChart = ({
  data,
  type,
  calculateRate,
  topCount,
  valueType = 'value',
}: {
  data?: HomeUserAnalysisResult[];
  type: 'system' | 'active' | 'interest' | 'city' | 'age' | 'gender';
  topCount?: number; //取TOP值显示
  calculateRate?: boolean; //计算百分比
  valueType?: 'value' | 'rate';
}) => {
  const [dataObj, total] = useCreation(() => {
    let datas = data?.filter((v) => v.audienceCharacterType == type);

    if (valueType == 'rate') {
      //返回的是小说百分比 比如20%  返回的是0.2
      datas = datas?.map((v) => ({ ...v, metrics: round(v.metrics * 100, 2) }));
    }

    const total = datas?.reduce((total: any, cur) => total + cur.metrics, 0) as number;

    if (topCount) {
      datas = datas?.sort((v1, v2) => v2.metrics - v1.metrics);
      datas = datas?.filter((_, index) => index < topCount);
    }

    const map = datas?.reduce(
      (obj, cur) => (obj[getConvertDimensionsName(cur.dimensions, type)] = cur.metrics) && obj,
      {},
    );
    return [map, total];
  }, [data, type]);

  return (
    <ChartCore
      empty={isEmpty(Object.keys(dataObj || {}))}
      option={{
        tooltip: {
          trigger: 'axis',
          formatter: '{b}： {c}%',
          axisPointer: {
            type: 'shadow',
          },
        },
        legend: {},

        xAxis: {
          type: 'category',
          data: Object.keys(dataObj || {}),
          offset: 10,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %',
          },
        },
        series: [
          {
            data: Object.values(dataObj || {}).map((value: any, index) => ({
              value: total ? (calculateRate ? round((value * 100) / total, 2) : value) : 0,
              itemStyle: {
                color: chortColors[index % chortColors.length],
              },
            })),
            barMaxWidth: '50px',
            type: 'bar',
          },
        ],
      }}
      notMerge
      style={{ height: 300 }}
    />
  );
};

export default RateBarChart;
