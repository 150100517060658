import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { cluesDownload, getCluesPage } from '@/services/clues/weixin';
import { ProCard } from '@ant-design/pro-components';
import { Button, Table } from '@antd';
import { Space } from '@antd/Antd';
import { useRequest, useSafeState } from 'ahooks';
import { snakeCase } from 'lodash';
import { CluesPageParam } from '@/services/clues/weixin/type';
import { ROUTE_PATH } from '@/constants/const';
import { Link } from 'umi';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

const List = ({ searchParams }: { searchParams: CluesPageParam }) => {
  const { user } = useAppContext();
  const [queryParams, setQueryParams] = useSafeState<any>({});

  const { run: download, loading: downloading } = useRequest(() => cluesDownload(queryParams), { manual: true });

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const sortParams: any = {};

      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = snakeCase(sorter.field);
        sortParams.direction = { ascend: 'asc', descend: 'desc' }[sorter.order];
      }

      const query = {
        ...searchParams,
        ...sortParams,
        bid: user?.bid,
      };
      setQueryParams(query);
      return getCluesPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [searchParams, user], debounceWait: 100 },
  );

  const { getBtnPermissionConfig } = usePermission();

  const columns = [
    getTableColumn({ title: dict('LEAD_ID'), dataIndex: 'clueId' }),
    getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),
    getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName' }),
    getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
    getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
    getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),
    getTableColumn({
      title: dict('PLATFORM_SHIPINGHAO'),
      dataIndex: 'accountName',
      width: 160,
      render: (val, record) => {
        return (
          <>
            {val || '-'}
            <br />
            {record.uniqueId && `(${record.uniqueId})`}
          </>
        );
      },
    }),
    getTableColumn({
      title: dict('NAME'),
      dataIndex: 'name',
      width: 160,
    }),
    getTableColumn({ title: dict('TELEPHONE'), dataIndex: 'phone' }),
    getTableColumn({ title: dict('CLUE_CRATE_TIME'), dataIndex: 'createTime', width: 150 }),
    getTableColumn({ title: dict('CITY'), dataIndex: 'region', width: 200 }),
    getTableColumn({
      title: dict('ACTION_OPERATE'),
      dataIndex: 'operation',
      fixed: 'right',
      width: 80,
      render(_, record) {
        return (
          <Link
            {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.VIEW)}
            to={`./${ROUTE_PATH.DETAIL}/${record.id}?clueId=${record.clueId}`}
          >
            {dict('ACTION_VIEW')}
          </Link>
        );
      },
    }),
  ];

  return (
    <ProCard>
      <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'space-between' }}>
        <Button
          loading={downloading}
          type="primary"
          onClick={download}
          {...getBtnPermissionConfig(AUTH.CUSTOMER_CLUE.DOWNLOAD)}
        >
          {dict('ACTION_DOWNLOAD_DATA')}
        </Button>
      </Space>
      <Table {...tableProps} rowKey="id" scroll={{ x: columns.length * 120 }} columns={columns} />
    </ProCard>
  );
};
export default List;
