import { HomeFansDailyResult } from '@/services/home/type';
import { CheckCard } from '@ant-design/pro-components';
import style from '../../style.less';
import { Format } from '@/utils/format';
import { useCreation, useSafeState } from 'ahooks';
import { get, isEmpty } from 'lodash';
import ChartCore from '@/components/ChartCore';
import { DateUtils } from '@/utils/dateUtil';
import { dict } from '@/hooks/useChangeLocale';

type Item = {
  count: number;
  date: string;
};

export const XhsFunsTrendLineChart = ({ data }: { data?: HomeFansDailyResult }) => {
  const [groupCheckedPanel, setGroupCheckedPanel] = useSafeState('totalFansDailyDetail');
  const items: Item[] = useCreation(
    () => (get(data, groupCheckedPanel, []) || []).sort((v1: Item, v2: Item) => (v1.date > v2.date ? 1 : -1)),
    [data, groupCheckedPanel],
  );

  const options = useCreation(() => {
    return [
      { title: dict('FANS_COUNT'), description: Format.toAmountNumber(data?.totalFans), value: 'totalFansDailyDetail' },
      {
        title: dict('TOTAL_NEW_FANS'),
        description: Format.toAmountNumber(data?.increaseFans),
        value: 'increaseFansDailyDetail',
      },
      {
        title: dict('TOTAL_LOST_FANS'),
        description: Format.toAmountNumber(data?.leaveFans),
        value: 'leaveFansDailyDetail',
      },
    ];
  }, [data, groupCheckedPanel]);

  return (
    <>
      <CheckCard.Group
        size="large"
        className={style['xhs-funs-trend-chart-wrap']}
        value={groupCheckedPanel}
        onChange={(item: any) => setGroupCheckedPanel(item)}
        style={{ display: 'flex', justifyContent: 'space-between' }}
        options={options}
      />

      <ChartCore
        empty={isEmpty(items)}
        option={{
          tooltip: {
            trigger: 'axis',
          },
          grid: {
            top: 30,
            left: 50,
            right: 50,
            bottom: 60,
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            offset: 10,
            data: items.map((v) => v.date),
            axisLabel: {
              formatter: function (value) {
                return `${DateUtils.format(value)}`;
              },
            },
          },
          yAxis: { type: 'value' },
          series: {
            name: options.find((item) => item.value == groupCheckedPanel)?.title,
            smooth: true,
            areaStyle: {
              opacity: 0,
            },
            type: 'line',
            data: items.map((v) => v.count),
          },
        }}
        notMerge
        style={{ height: 350 }}
      />
    </>
  );
};

export default XhsFunsTrendLineChart;
