import { EmojiImgText } from '@/components/EmojiImg';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { getKeywords } from '@/pages/system/keyword/const.var';
import { QueryKeywordCategoryItem } from '@/services/keyword_category/type';
import { downloadComment, getCommentPage } from '@/services/live_comment';
import { DATE_MINI_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Button, Table, TableColumnType } from '@antd';
import { useCreation, useRequest } from 'ahooks';
import { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { get, isEmpty, isUndefined, set } from 'lodash';
import { Key, useState } from 'react';
import { Unclassifyied } from './LeftNav';
import { ProCard } from '@ant-design/pro-components';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

type ListPros = {
  roomId: Key;
  event$: EventEmitter<any>;
  filter: any;
};

const List = ({ roomId, event$, filter }: ListPros) => {
  const { user } = useAppContext();
  const [keyword, setKeyword] = useState('');
  const [keywordCategoryList, setKeywordCategroyList] = useState<QueryKeywordCategoryItem[]>([]);
  const [params, setParams] = useState({ queryAll: false, categoryIds: undefined });
  const [downloadParams, setDownloadParams] = useState<any>();
  const [tableFilters, setTableFilters] = useState<any>({});

  //列表数据加载
  const { tableProps, ...tableRequest } = useTableRequest(
    async ({ pageIndex, pageSize, filters = {}, sorter }) => {
      setTableFilters(filters);
      const { nickName, content, title, categoryList } = filters;
      const keyword = get(content, '[0]');
      const { categoryIds, queryAll } = params;
      if (isUndefined(categoryIds)) return { list: [] };
      const hasNoFilterCategory = isEmpty(categoryList);
      const queryUnclassified = categoryIds == Unclassifyied;
      const ids = queryAll
        ? hasNoFilterCategory
          ? categoryIds
          : categoryList?.join(',')
        : queryUnclassified
          ? keywordCategoryList.map((v) => v.id).join(',') //未分类
          : categoryIds; //指定目录

      const [startTime, endTime] = DateUtils.convertDateTimeRangeStr(filter.publishTime);
      const sortParams: any = {};
      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = sorter.field;
        sortParams.direction = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
      }

      const queryParams: any = {
        ...sortParams,
        queryAll: queryAll && hasNoFilterCategory,
        roomId,
        searchWord: keyword,
        nickName: get(nickName, '[0]'),
        title: get(title, '[0]'),
        bid: user?.bid,
        categoryIds: ids,
        queryUnclassified,
        startTime,
        endTime,
      };
      setKeyword(keyword);
      setDownloadParams(queryParams);

      return getCommentPage({
        ...queryParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, user, filter, keywordCategoryList], debounceWait: 100 },
  );

  const { run: download, loading: downloading } = useRequest(downloadComment, { manual: true });

  //更新左侧事件响应
  event$.useSubscription(({ action, value }) => {
    if (action == 'SET_CATEGORY_LIST') setKeywordCategroyList(value);
    if (action == 'REFRESH_TABLE') {
      // 重置Table的筛选条件
      set(tableRequest, 'params[0].filters', {});
      setParams({ ...value });
    }
  });

  const TableList = useCreation(() => {
    return (props) => <Table {...props} />;
  }, [params]);

  const { getBtnPermissionConfig } = usePermission();

  return (
    <ProCard
      extra={
        <Button
          size="small"
          loading={downloading}
          type="link"
          {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}
          onClick={() => download(downloadParams)}
        >
          {dict('ACTION_DOWNLOAD_DATA')}
        </Button>
      }
    >
      <TableList
        {...tableProps}
        scroll={{ y: 500, x: undefined }}
        columns={
          [
            getTableColumn({
              title: dict('COMMENT_TIME'),
              dataIndex: 'create_time',
              sorter: true,
              align: 'left',
              render: (_, record) => {
                return (
                  <>
                    <div>{DateUtils.format(record.createTime, DATE_MINI_FORMAT_STR)}</div>
                    <div style={{ color: '#999', marginTop: 3 }}>{record.id}</div>
                  </>
                );
              },
            }),
            getTableColumn({
              title: dict('NICKNAME'),
              dataIndex: 'nickName',
              ...getColumnSearchProps({
                dataIndex: 'nickName',
              }),
            }),

            getTableColumn({
              title: dict('BULLET_CONTENT'),
              dataIndex: 'content',
              width: 200,
              align: 'left',
              ...getColumnSearchProps({
                dataIndex: 'content',
              }),
              render: (v, record) => (
                <EmojiImgText content={v} searchWord={keyword} keywords={getKeywords(record.keywordMap)} />
              ),
            }),

            params.queryAll
              ? getTableColumn({
                  title: dict('SEMANTIC_CLASSIFICATION'),
                  dataIndex: 'categoryList',
                  ellipsis: true,
                  filters: keywordCategoryList.map(({ id, name }: any) => ({ text: name, value: id })),
                  filteredValue: filter.categoryList,
                  render: (labels) => labels?.join(',') || '-',
                })
              : null,

            params.categoryIds == Unclassifyied
              ? null
              : getTableColumn({
                  title: dict('KEYWORD_HIT'),
                  dataIndex: 'keywordList',
                  ellipsis: true,
                  render: (labels) => labels?.join(',') || '-',
                }),
          ]
            .filter(Boolean)
            .map((v) => ({
              ...v,
              filteredValue: v?.filteredValue ?? tableFilters[(v as any).dataIndex] ?? null,
            })) as TableColumnType<any>[]
        }
      />
    </ProCard>
  );
};

export default List;
