import { RETURL_ROUTE, ROUTE_PATH, defaultRoutePath } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { Locales, dict, supportLaunguageKeys, useChangeLanguageLocale } from '@/hooks/useChangeLocale';
import { themeConfig } from '@/layouts/const/themes';
import { getCaptchaCode } from '@/services/cappcha';
import { login } from '@/services/user';
import { getSessionStorage, setSessionStorage } from '@/utils/storage';
import { GlobalOutlined, KeyOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { LoginForm, ProFormText } from '@ant-design/pro-components';
import { App, Dropdown, Image, Space, Typography } from '@antd';
import { useBoolean, useMount, useRequest, useTitle } from 'ahooks';
import { useLocation, useNavigate } from 'umi';
import './index.less';

const { LOGIN } = ROUTE_PATH;
export default () => {
  const { message } = App.useApp();
  const { setUser, user } = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [refeshKey, { toggle: refsheImgURL }] = useBoolean();

  useTitle(`${dict('LOGIN')} - ${dict('APP_NAME')}`);
  const { data: dataImg } = useRequest(getCaptchaCode, { refreshDeps: [refeshKey] });

  useMount(() => {
    if (user) {
      navigate(`/${defaultRoutePath}`);
      return;
    }
    if (pathname == `/${LOGIN}`) return;
    setSessionStorage(RETURL_ROUTE, pathname);
    navigate(`/${LOGIN}`);
  });

  const { languageKey, changeLanguageLocale } = useChangeLanguageLocale();

  return (
    <LoginForm
      logo={themeConfig.loginLogo}
      title={dict('APP_NAME')}
      subTitle={<div />}
      onFinish={async (data: any) => {
        const { loginName, password, code } = data;
        try {
          const user = await login(loginName, password, dataImg?.uuid, code);
          setUser(user);
          navigate(getSessionStorage(RETURL_ROUTE) || `/${defaultRoutePath}`);
        } catch (error: any) {
          setTimeout(refsheImgURL);
          if (error?.status.code == 'LG003') {
            return message.error(error.status.message);
          }
          return message.error(dict('ERROR_USER_PWD_TIP'));
        }
        return data;
      }}
      submitter={{
        searchConfig: { submitText: dict('LOGIN') },
      }}
    >
      <div
        style={{
          display: 'flex',
          height: 50,
          marginTop: -50,
          alignItems: 'center',
          justifyContent: 'right',
        }}
      >
        <Dropdown
          key={languageKey}
          menu={{
            onClick: (e) => changeLanguageLocale(e.key as Locales),
            items: supportLaunguageKeys.map((v) => ({
              ...v,
              disabled: v.key == languageKey,
            })),
          }}
        >
          <Typography.Link>
            {dict('MULTILINGUAL')} <GlobalOutlined />
          </Typography.Link>
        </Dropdown>
      </div>

      <ProFormText
        name="loginName"
        fieldProps={{
          size: 'large',
          prefix: <UserOutlined className={'prefixIcon'} />,
        }}
        placeholder={dict('USERNAME_INPUT_TIP')}
        rules={[
          {
            required: true,
            message: dict('USERNAME_INPUT_TIP'),
          },
        ]}
      />
      <ProFormText.Password
        name="password"
        fieldProps={{
          size: 'large',
          prefix: <LockOutlined className={'prefixIcon'} />,
        }}
        placeholder={dict('PASSWORD_INPUT_TIP')}
        rules={[
          {
            required: true,
            message: dict('PASSWORD_INPUT_TIP'),
          },
        ]}
      />

      <Space>
        <ProFormText
          name="code"
          style={{ display: 'flex', alignItems: 'center', border: '1px solid red' }}
          rules={[
            {
              required: true,
              message: dict('VERIFICATION_CODE_INPUT_TIP'), //请输入验证码
            },
          ]}
          fieldProps={{
            size: 'large',
            prefix: <KeyOutlined className="prefixIcon" />,
          }}
          placeholder={dict('VERIFICATION_CODE_INPUT_TIP')}
        />
        {dataImg && (
          <Image
            src={dataImg?.img}
            preview={false}
            style={{ width: 100, paddingBottom: 25, cursor: 'pointer' }}
            onClick={refsheImgURL}
          />
        )}
      </Space>
    </LoginForm>
  );
};
