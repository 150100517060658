import { StatisticCardPanel, StatisticItem } from '@/components/StatisticCard';
import { Format } from '@/utils/format';
import { indicatorMap, LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { DateUtils } from '@/utils/dateUtil';
import { WxLiveStreamDetail, WxLiveStreamTotalTrendVO } from '@/services/live_stream/wx/type';
import { ProCard } from '@ant-design/pro-components';
import { headerFormCardProps } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type DataStatisticCardProps = {
  liveData?: WxLiveStreamDetail;
  totalTrendData?: WxLiveStreamTotalTrendVO;
};

const DataStatisticCard = ({ liveData, totalTrendData }: DataStatisticCardProps) => {
  const loading = !liveData;
  const audienceItems: StatisticItem[] = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, // 累积观看人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveUserRealtime" />, // 实时在线人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveUserRealtime),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveAvgWatchDuration" />, // 人均观看时长
      mValues: DateUtils.formatSecondsToArray(liveData?.lpScreenLiveAvgWatchDuration) || [
        {
          value: '-',
          suffix: dict('STATIST_UNIT_SEC'),
        },
      ],
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveMaxWatchUvByMinute" />, // 最高在线人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveMaxWatchUvByMinute),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle title={dict('ADD_FOLLOW')} tip={indicatorMap.lpScreenLiveFollowUv.tip} />,
      value: Format.toAmountNumber(liveData?.cumulativeNewFollowUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: dict('TOTAL_HEAT'),
      value: Format.toAmountNumber(liveData?.cumulativeHotQuota),
    },
  ];

  const interactionItems = [
    {
      title: (
        <LiveIndicatorTitle title={dict('SHARE_LIVE_ROOM_USER_COUNT')} tip={indicatorMap.lpScreenLiveShareUv.tip} />
      ),
      value: Format.toAmountNumber(liveData?.lpScreenLiveShareUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: (
        <LiveIndicatorTitle title={dict('SHARE_LIVE_ROOM_USER_COUNT')} tip={indicatorMap.lpScreenLiveShareCount.tip} />
      ),
      value: Format.toAmountNumber(liveData?.lpScreenLiveShareCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveFansClubJoinUv" />, // 加入粉丝团人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveFansClubJoinUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveCommentUv" />, // 评论人数
      value: Format.toAmountNumber(liveData?.lpScreenLiveCommentUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />, // 评论次数
      value: Format.toAmountNumber(liveData?.lpScreenLiveCommentCount),
      suffix: dict('STATIST_UNIT_CI'),
    },

    {
      title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />, // 点赞次数
      value: Format.toAmountNumber(liveData?.lpScreenLiveLikeCount),
      suffix: dict('STATIST_UNIT_CI'),
    },
    {
      title: dict('GIFT_GIVE_USER_COUNT'),
      value: Format.toAmountNumber(liveData?.cumulativeRewardUv),
    },
    {
      title: dict('GIFT_GIVE_HEAT'),
      value: Format.toAmountNumber(liveData?.cumulativeRewardAmountInHeatAnchor),
    },
  ];

  const trendItems = [
    {
      title: <LiveIndicatorTitle type="lpScreenLiveAvgOnlineUvByRoom" />, // 平均在线人数
      value: Format.toAmountNumber(totalTrendData?.averageOnlineWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: dict('ENTER_ROOM_USER_COUNT'),
      value: Format.toAmountNumber(totalTrendData?.newWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
    {
      title: dict('LEAVE_ROOM_USER_COUNT'),
      value: Format.toAmountNumber(totalTrendData?.leavedWatchUv),
      suffix: dict('STATIST_UNIT_REN'),
    },
  ];

  return (
    <ProCard.Group gutter={[16, 16]} wrap>
      <ProCard {...headerFormCardProps} title={dict('DATA_OVERVIEW')}>
        <StatisticCardPanel loading={loading} items={audienceItems} columns={5} />
      </ProCard>
      <ProCard {...headerFormCardProps} title={dict('LIVE_STATIST_INTERACTIVE_DATA')}>
        <StatisticCardPanel loading={loading} items={interactionItems} columns={5} />
      </ProCard>
      <ProCard {...headerFormCardProps} title={dict('TRAFFIC_AND_SOURCE_TREND')}>
        <StatisticCardPanel loading={loading} items={trendItems} columns={5} />
      </ProCard>
    </ProCard.Group>
  );
};

export default DataStatisticCard;
