import ChartCore from '@/components/ChartCore';
import { HomeUserAnalysisResult } from '@/services/home/type';
import { useCreation } from 'ahooks';
import { getPieChartOptions } from './chart.config';
import { isEmpty } from 'lodash';
import { dict } from '@/hooks/useChangeLocale';

const WEIXIN_TOTAL_FIELD_KEY = '@_all';
const SystemDimensionsMap = {
  1: 'IOS',
  2: 'Android',
  unknow: dict('UNKNOWN'),
};
const PieChart = ({ data }: { data?: HomeUserAnalysisResult[]; type: 'system' | 'device_list' }) => {
  const list = useCreation(() => {
    return data
      ?.filter((v) => ['device_list', 'system'].includes(v.audienceCharacterType))
      .filter((v) => v.dimensions != WEIXIN_TOTAL_FIELD_KEY)
      .map((v) => {
        const name: string = SystemDimensionsMap[v.dimensions] || v.dimensions;
        return {
          name,
          value: v.metrics,
        };
      });
  }, [data]);

  return (
    <ChartCore empty={isEmpty(list)} option={getPieChartOptions(list || [], true)} notMerge style={{ height: 300 }} />
  );
};

export default PieChart;
