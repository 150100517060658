import ChartCore from '@/components/ChartCore';
import { dict } from '@/hooks/useChangeLocale';
import { WxLiveStreamDetail } from '@/services/live_stream/wx/type';
import { Format } from '@/utils/format';
import { useCreation } from 'ahooks';
import { isEmpty } from 'lodash';

export const getAgeLabel = (name: string) => {
  if (name.endsWith('-')) return dict('LIVE_STATIST_AGE_ABOUT', { name: name.replace('_', '') });
  return /\d/.test(name) ? dict('LIVE_STATIST_AGE', { age: name }) : name;
};

export const getAgeTooltip = (item: { name: string; value: number }, sumValue: number) => {
  return `${item.name}: ${((item.value / sumValue) * 100).toFixed(1)}%`;
};

const Chart = ({
  data,
  liveStreamData,
  formatName,
  formatTooltip,
  formatLabel,
}: {
  data: any;
  liveStreamData?: WxLiveStreamDetail;
  formatName?: (name: any) => string;
  formatTooltip?: (params: any) => string;
  formatLabel?: (params: any) => string;
}) => {
  const options = useCreation(
    () => ({
      legend: {
        left: 'center',
      },
      tooltip: {
        formatter: formatTooltip || '{b}: {d}%',
      },
      title: {
        text: dict('LIVE_VIEW_USER_COUNT'),
        subtext: Format.toAmountNumber(liveStreamData?.lpScreenLiveWatchUv),
        left: 'center',
        top: 'center',
      },
      series: [
        {
          type: 'pie',
          radius: ['40%', '60%'],
          percentPrecision: 1,
          label: {
            show: true,
            formatter: formatLabel || '{b}: {d}%',
          },
          data: formatName
            ? data.map((item) => ({
                ...item,
                name: formatName(item.name),
              }))
            : data,
        },
      ],
    }),
    [data, liveStreamData],
  );
  return <ChartCore option={options} notMerge empty={isEmpty(data)} style={{ height: 300 }} />;
};

export default Chart;
