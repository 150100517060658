import { AccountAvator } from '@/components/AccountAvator';
import { LiveStreamData } from '@/services/live_stream/all/type';
import { WxLiveStreamData } from '@/services/live_stream/wx/type';
import { DateUtils } from '@/utils/dateUtil';
import { Card, Descriptions, Spin } from '@antd';
import style from './index.less';
import { MEDIA_KEY_ENUM, getMediaName } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type BaseInfoProps = {
  detail?: LiveStreamData | WxLiveStreamData;
};
export const BasicInfo = ({ detail }: BaseInfoProps) => {
  return (
    <Spin spinning={!detail}>
      <Card bordered={false} className={style['basic-info']}>
        <Card.Grid hoverable={false}>
          <Descriptions title={<AccountAvator avaterUrl={detail?.avatarUrl} name={detail?.name || ''} />} column={4}>
            <Descriptions.Item label={dict('PLATFORM')}>
              {getMediaName(detail?.mediaId as MEDIA_KEY_ENUM)}
            </Descriptions.Item>
            <Descriptions.Item label={dict('LIVE_ROOM_ID')}>{detail?.roomId}</Descriptions.Item>

            {detail?.mediaId == MEDIA_KEY_ENUM.DOUYIN && (
              <Descriptions.Item label={dict('LIVE_COVER_TITLE')}>{detail?.liveStreamCoverTitle}</Descriptions.Item>
            )}
            <Descriptions.Item label={dict('AFFILIATION_ORG')}>{detail?.orgFullName}</Descriptions.Item>
            <Descriptions.Item label={dict('LIVE_TIME')}>
              {DateUtils.formatDateTime(detail?.liveStartTime)}
            </Descriptions.Item>
            <Descriptions.Item label={dict('LIVE_END_TIME')}>
              {DateUtils.formatDateTime(detail?.liveEndTime)}
            </Descriptions.Item>
            <Descriptions.Item label={dict('LIVE_DURATION')}>
              {DateUtils.formatDiffrenceTime(detail?.liveDuration || 0)}
            </Descriptions.Item>
          </Descriptions>
        </Card.Grid>
      </Card>
    </Spin>
  );
};
