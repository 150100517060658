import ChartCore from '@/components/ChartCore';
import { chortColors } from '@/components/ChartCore/theme';
import { DyClueAnalysisVO } from '@/services/live_stream/dy/type';
import { Format } from '@/utils/format';
import { Validator } from '@/utils/validator';
import { useCreation } from 'ahooks';
import { sum } from 'lodash';

const Chart = ({
  dyClueAnalysisData,
  type,
  showPercent,
}: {
  dyClueAnalysisData?: DyClueAnalysisVO;
  type: 'tool' | 'toolPv' | 'province' | 'city' | 'watchDuration';
  showPercent?: boolean;
}) => {
  const data = useCreation(() => {
    return dyClueAnalysisData?.[type];
  }, [dyClueAnalysisData, type]);

  const total = sum(Object.values(data ?? {}));
  const options = useCreation(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: '{b}： {c}',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: Object.keys(data ?? {}),
      },
      series: [
        {
          type: 'bar',
          realtimeSort: true,
          label: {
            show: true,
            formatter: showPercent
              ? function (params) {
                  return Format.toPercent(total ? params.value / total : 0);
                }
              : undefined,
          },

          data: Object.values(data || {}).map((value: any, index) => ({
            value,
            itemStyle: {
              color: chortColors[index % chortColors.length],
            },
          })),
        },
      ],
    }),
    [data],
  );

  return <ChartCore option={options} notMerge empty={Validator.isNilEmpty(data)} style={{ height: 300 }} />;
};

export default Chart;
