import ChartCore from '@/components/ChartCore';
import { useCreation } from 'ahooks';

const Chart = ({ data }: { data: any }) => {
  const options = useCreation(
    () => ({
      tooltip: {
        trigger: 'axis',
        formatter: '{b}: {c}%',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      xAxis: {
        type: 'value',
      },
      yAxis: {
        type: 'category',
        offset: 10,
        boundaryGap: ['20%', '20%'],
        inverse: true,
        data: Object.keys(data ?? {}),
      },
      series: [
        {
          type: 'bar',
          label: {
            show: true,
            formatter: '{c}%',
            offset: [15, 0],
          },
          realtimeSort: true,
          data: Object.values(data ?? {}),
        },
      ],
    }),
    [data],
  );

  return <ChartCore option={options} notMerge empty={!data} style={{ height: 300 }} />;
};

export default Chart;
