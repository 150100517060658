import { themeConfig } from '@/layouts/const/themes';
import { ProLayout, ProLayoutProps } from '@ant-design/pro-components';
import { Function1, isEmpty } from 'lodash';
import React, { ReactNode } from 'react';
import { useLocation, useNavigate } from 'umi';
import { Menu, getMenus, getMenuItem, selectedMenu, convetRouteNameMap } from '../../const/config.var';
import './VerticalLayout.less';
import { Tabs } from '@antd';
import GoBackBar from '@/components/GoBackBar';
import { useCreation } from 'ahooks';
import { Validator } from '@/utils/validator';
import { useAppContext } from '@/contexts/AppContext';
import { AuthKey } from '@/constants/permission-code';

const { isNilEmpty } = Validator;

const convetMenuToRoute = (
  hasPermission: Function1<AuthKey | AuthKey[], boolean>,
  menus: Menu[] | undefined,
  parentRoute = '',
) => {
  if (!menus || isEmpty(menus)) return undefined;
  return [...menus]
    .filter((v) => (v.permissionKey ? hasPermission(v.permissionKey as AuthKey) : true))
    .map((v) => ({
      ...v,
      path: parentRoute + v.path,
      menus: undefined,
      className: 'root-item',
      routes: convetMenuToRoute(hasPermission, v.menus, parentRoute + v.path),
    }));
};

const headerTheme = {
  colorPrimary: themeConfig.colorPrimary,
  colorBgAppListIconHover: 'rgba(0,0,0,0.06)',
  colorTextAppListIcon: 'rgba(255,255,255,0.85)',

  header: {
    colorBgHeader: themeConfig.colorPrimary,
    colorBgRightActionsItemHover: 'rgba(0,0,0,0.06)',
    colorTextRightActionsItem: 'rgba(255,255,255,0.65)',
    colorHeaderTitle: '#fff',
    colorBgMenuItemHover: 'rgba(0,0,0,0.06)',
    colorBgMenuItemSelected: 'rgba(0,0,0,0.15)',
    colorTextMenuSelected: '#fff',
    colorTextMenu: 'rgba(255,255,255,0.75)',
    colorTextMenuSecondary: 'rgba(255,255,255,0.65)',
    colorTextMenuActive: 'rgba(255,255,255,0.95)',
  },
};

export const VerticalLayout = ({ children, header, ...rest }: ProLayoutProps & { header: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { platforms } = useAppContext();
  const routeMenus = getMenus(platforms);

  //路由路径和菜单名的映射
  const routeNameMap = convetRouteNameMap(routeMenus);
  const navName = routeNameMap[pathname];
  const menus = useCreation(() => getMenuItem(pathname, platforms).menus, [pathname]);
  const selectMenu = useCreation(() => selectedMenu(pathname, menus), [pathname, menus, platforms]);

  // 获取三级路由
  const slibingMenus = useCreation(() => {
    if (!selectMenu) return null;
    const parentMenu: any = menus?.find((item: any) =>
      (item.children || item.items || item.routes)?.includes(selectMenu),
    );
    return parentMenu?.children || parentMenu?.items || parentMenu?.routes;
  }, [menus, selectMenu]);

  const tab =
    navName &&
    (!isNilEmpty(slibingMenus) && slibingMenus.length > 1 ? (
      <div className="vertical-layout-page-header-second">
        <Tabs size="small" activeKey={selectMenu?.key} items={slibingMenus} onTabClick={(key) => navigate(key)} />
      </div>
    ) : (
      <GoBackBar name={navName} back={false} />
    ));

  const { hasPermission } = useAppContext();

  return (
    <>
      <ProLayout
        route={{ path: '/', routes: convetMenuToRoute(hasPermission, routeMenus) }}
        location={location}
        token={headerTheme}
        breadcrumbRender={false}
        layout="top"
        menuProps={{
          // triggerSubMenuAction: 'click',
          getPopupContainer(node) {
            return node.closest('header') as HTMLElement;
          },
        }}
        menuItemRender={({ path }: any, dom: any) => {
          return (
            <div
              onClickCapture={() => {
                navigate(path as string);
              }}
            >
              {dom}
            </div>
          );
        }}
        actionsRender={() => [header]}
        {...rest}
      >
        {tab}
        {children}
      </ProLayout>
    </>
  );
};
